import gameState, { User, Player } from '../stores/State';
import { signOutOfFirebase } from '../firebase/firebase-auth';
import { persistPlayer } from '../services/playerService';
import { removeUserId } from '../firebase/firebase-helpers';

export function signUp({ user }) {
    const internalUser = new User(user);
    gameState.setUserAuth(internalUser);
    persistPlayer(internalUser.id, new Player(internalUser));
}

export function signIn({ user, afterSignIn }) {
    const internalUser = new User(user);
    gameState.setUserAuth(internalUser);
    user.getIdTokenResult().then((idTokenResult) => {
        gameState.setAdmin(!!idTokenResult?.claims?.admin);
        gameState.initAuth();
        afterSignIn && afterSignIn();
    });
}

export function signOut() {
    let shouldReload = false;
    if (gameState.isAuthenticated) {
        shouldReload = true;
    }

    gameState.initAuth();
    gameState.clearUser();
    gameState.clearCurrentPlayer();

    if (gameState.groupId) {
        removeUserId({ userId: gameState.userId, groupId: gameState.groupId });
    }

    signOutOfFirebase();

    shouldReload && window.location.reload(false);
}
