import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './CameraSettingsComponent.module.scss';
import Webcam from 'react-webcam';
import Flex from '../common/Flex';
import { MenuItem, Select } from '@material-ui/core';
import { VmShape, VolumeMeter } from './audioMeter/src';
import { useLanguageContext } from '../components/LanguageWrapper';

export default function CameraSettingsComponent({ className, darkText, onDeviceChange }) {
    const [videoDevices, setVideoDevices] = useState({});
    const [audioDevices, setAudioDevices] = useState({});
    const [selectedVideoDevice, setSelectedVideoDevice] = useState(
        localStorage.getItem('myquiz.video.deviceId') || null
    );
    const [selectedAudioDevice, setSelectedAudioDevice] = useState(
        localStorage.getItem('myquiz.audio.deviceId') || null
    );
    const changeVideo = useCallback(
        (deviceId) => {
            setSelectedVideoDevice(deviceId);
            localStorage.setItem('myquiz.video.deviceId', deviceId || null);
            // eslint-disable-next-line no-unused-expressions
            onDeviceChange?.('video', deviceId);
        },
        [onDeviceChange]
    );
    const changeAudio = useCallback(
        (deviceId) => {
            setSelectedAudioDevice(deviceId);
            localStorage.setItem('myquiz.audio.deviceId', deviceId || null);
            // eslint-disable-next-line no-unused-expressions
            onDeviceChange?.('audio', deviceId);
        },
        [onDeviceChange]
    );
    useEffect(() => {
        async function init() {
            const results = await getDevices();
            setVideoDevices(results.video);
            setAudioDevices(results.audio);
        }
        init();
    }, []);
    return (
        <div className={cx(className, styles.main, { [styles.darkText]: darkText })}>
            <div className={styles.outerGrid}>
                <CameraFeed
                    videoDevices={videoDevices}
                    selectedVideoDevice={selectedVideoDevice}
                    audioDevices={audioDevices}
                    selectedAudioDevice={selectedAudioDevice}
                />
                <CameraSelector
                    videoDevices={videoDevices}
                    selectedVideoDevice={selectedVideoDevice}
                    setSelectedVideoDevice={changeVideo}
                />
                <AudioSelector
                    audioDevices={audioDevices}
                    selectedAudioDevice={selectedAudioDevice}
                    setSelectedAudioDevice={changeAudio}
                />
            </div>
        </div>
    );
}

async function getDevices() {
    const [videoResult, audioResult] = await Promise.allSettled([getDevicesType('video'), getDevicesType('audio')]);
    return {
        video: videoResult.status === 'fulfilled' ? { devices: videoResult.value } : { error: videoResult.reason },
        audio: audioResult.status === 'fulfilled' ? { devices: audioResult.value } : { error: audioResult.reason },
    };
}

async function getDevicesType(type) {
    await navigator.mediaDevices.getUserMedia(type === 'video' ? { video: true } : { audio: true });
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    const wantedKind = type === 'video' ? 'videoinput' : 'audioinput';
    return mediaDevices.filter(({ kind }) => kind === wantedKind);
}

function CameraFeed({ videoDevices, selectedVideoDevice, audioDevices, selectedAudioDevice }) {
    const [audioStream, setAudioStream] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    useEffect(() => {
        async function run() {
            const audioDeviceId = getSelectedId(audioDevices, selectedAudioDevice);
            if (audioDeviceId) {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: audioDeviceId } });
                setAudioStream(stream);
                var AudioContext = window.AudioContext || window.webkitAudioContext;
                var audioCtx = new AudioContext();
                setAudioContext(audioCtx);
            }
        }
        run();
    }, [audioDevices, selectedAudioDevice]);
    return (
        <div className={styles.cameraFeed}>
            {videoDevices.devices ? (
                <div className={styles.cameraActualFeed}>
                    <Webcam
                        width='100%'
                        audio={false}
                        videoConstraints={{ deviceId: getSelectedId(videoDevices, selectedVideoDevice) }}
                    />
                </div>
            ) : (
                <div className={styles.noCamera} />
            )}
            {audioStream && audioContext ? (
                <VolumeMeter
                    className={styles.volumeMeter}
                    shape={VmShape.VM_FLAT}
                    audioContext={audioContext}
                    stream={audioStream}
                    blocks={20}
                    height={10}
                    width={200}
                />
            ) : (
                <div className={styles.noVolume} />
            )}
        </div>
    );
}

function CameraSelector({ videoDevices, selectedVideoDevice, setSelectedVideoDevice }) {
    const { str } = useLanguageContext();
    return (
        <div className={styles.cameraSelector}>
            <CameraStatus videoDevices={videoDevices} />
            {videoDevices.devices ? (
                <Enumerator
                    text={str('mediaSettings.camera')}
                    devices={videoDevices.devices}
                    onDeviceIdChange={setSelectedVideoDevice}
                    currentDeviceId={getSelectedId(videoDevices, selectedVideoDevice)}
                />
            ) : null}
        </div>
    );
}

function CameraStatus({ videoDevices }) {
    const { str } = useLanguageContext();
    if (videoDevices.devices && !videoDevices.error) {
        return <Status type='success' message={str('mediaSettings.camera.success')} />;
    }
    if (videoDevices.error) {
        switch (videoDevices.error.name) {
            case 'NotAllowedError':
                return <Status type='error' message={str('mediaSettings.camera.notAllowed')} />;
            case 'NotFoundError':
                return <Status type='error' message={str('mediaSettings.camera.notFound')} />;
            case 'NotReadableError':
                return <Status type='error' message={str('mediaSettings.camera.notReadable')} />;
            default:
                return <Status type='error' message={str('mediaSettings.camera.error')} />;
        }
    }
    return <Status type='intermediate' message={str('mediaSettings.camera.pending')} />;
}

function AudioStatus({ audioDevices }) {
    const { str } = useLanguageContext();
    if (audioDevices.devices && !audioDevices.error) {
        return <Status type='success' message={str('mediaSettings.mic.success')} />;
    }
    if (audioDevices.error) {
        switch (audioDevices.error.name) {
            case 'NotAllowedError':
                return <Status type='error' message={str('mediaSettings.mic.notAllowed')} />;
            case 'NotFoundError':
                return <Status type='error' message={str('mediaSettings.mic.notFound')} />;
            case 'NotReadableError':
                return <Status type='error' message={str('mediaSettings.mic.notReadable')} />;
            default:
                return <Status type='error' message={str('mediaSettings.mic.error')} />;
        }
    }
    return <Status type='intermediate' message={str('mediaSettings.mic.pending')} />;
}

function Status({ type, message }) {
    const className = type === 'success' ? styles.success : type === 'error' ? styles.error : styles.intermediate;
    return (
        <div className={cx(styles.status, className)}>
            <label>{message}</label>
        </div>
    );
}

function AudioSelector({ audioDevices, selectedAudioDevice, setSelectedAudioDevice }) {
    const { str } = useLanguageContext();
    return (
        <div className={styles.audioSelector}>
            <AudioStatus audioDevices={audioDevices} />
            {audioDevices.devices ? (
                <Enumerator
                    text={str('mediaSettings.mic')}
                    devices={audioDevices.devices}
                    onDeviceIdChange={setSelectedAudioDevice}
                    currentDeviceId={getSelectedId(audioDevices, selectedAudioDevice)}
                />
            ) : null}
        </div>
    );
}

function getSelectedId(deviceObj, selectedDevice) {
    return selectedDevice || deviceObj?.devices?.[0]?.deviceId;
}

function Enumerator({ text, devices, onDeviceIdChange, currentDeviceId }) {
    const onDeviceChange = useCallback(
        (event) => {
            onDeviceIdChange(event.target.value);
        },
        [onDeviceIdChange]
    );
    return (
        <Flex align='center' className={styles.enumerator}>
            <label className={styles.type}>{text}</label>
            {devices.length ? (
                <Select
                    className={styles.deviceSelect}
                    value={currentDeviceId || devices[0]?.deviceId}
                    onChange={onDeviceChange}
                >
                    {devices.map((device, index) => {
                        return (
                            <MenuItem key={device.deviceId} value={device.deviceId}>
                                {device.label || `${text} ${index + 1}`}
                            </MenuItem>
                        );
                    })}
                </Select>
            ) : null}
        </Flex>
    );
}
