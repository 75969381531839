import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animator } from './Animator';
import { BlockRenderer } from './BlockRenderer';
import { CircleRenderer } from './CircleRenderer';
import { getCanvasContext, monitorTrack } from './functions';
import { Alert, Clickable, MeterDisplay, StyledVolumeMeter } from './layout';
export var VmShape;
(function (VmShape) {
    VmShape[(VmShape['VM_CIRCLE'] = 0)] = 'VM_CIRCLE';
    VmShape[(VmShape['VM_STEPPED'] = 1)] = 'VM_STEPPED';
    VmShape[(VmShape['VM_FLAT'] = 2)] = 'VM_FLAT';
})(VmShape || (VmShape = {}));
const defaultActivateButton = (onClick) =>
    React.createElement('button', { type: 'button', onClick: onClick }, 'Activate');
export const VolumeMeter = ({
    enabled = true,
    width,
    height,
    shape,
    blocks = 5,
    audioContext,
    stream,
    activateButton = defaultActivateButton,
    className,
}) => {
    const canvas = useRef(null);
    const [contextState, setContextState] = useState(audioContext.state);
    const [trackEnabled, setTrackEnabled] = useState(true);
    const [unableToProvideData, setUnableToProvideData] = useState(false);
    const [hasEnded, setHasEnded] = useState(false);
    const rendererRef = useRef(null);
    const animatorRef = useRef(null);
    const onStateChange = useCallback(() => {
        setContextState(audioContext.state);
    }, [audioContext]);
    const enableTrack = (e) => {
        if (stream) {
            const s = stream;
            const tr = s.getAudioTracks()[0];
            tr.enabled = e;
        }
    };
    const unmuteButton = () =>
        React.createElement(
            React.Fragment,
            null,
            'Audio is muted.',
            ' ',
            React.createElement(
                Clickable,
                {
                    onClick: () => {
                        enableTrack(true);
                    },
                },
                'Click to unmute'
            )
        );
    useEffect(() => {
        audioContext.addEventListener('statechange', onStateChange);
        return () => {
            audioContext.removeEventListener('statechange', onStateChange);
        };
    }, [audioContext, onStateChange]);
    const onUnableToProvideData = useCallback(() => {
        setUnableToProvideData(true);
    }, []);
    const onAbleToProvideData = useCallback(() => {
        setUnableToProvideData(false);
    }, []);
    const onTrackHasEnded = useCallback(() => {
        setHasEnded(true);
    }, []);
    useEffect(() => {
        if (stream) {
            const s = stream;
            const tr = s.getAudioTracks()[0];
            if (tr) {
                setTrackEnabled(tr.enabled);
                monitorTrack(tr, {
                    onEnabledChanged: setTrackEnabled,
                    onStopCalled: () => {
                        setHasEnded(true);
                    },
                });
                setUnableToProvideData(tr.muted);
                tr.addEventListener('mute', onUnableToProvideData);
                tr.addEventListener('unmute', onAbleToProvideData);
                setHasEnded(tr.readyState === 'ended');
                tr.addEventListener('ended', onTrackHasEnded);
            }
            return () => {
                tr.removeEventListener('mute', onUnableToProvideData);
                tr.removeEventListener('unmute', onAbleToProvideData);
                tr.removeEventListener('ended', onTrackHasEnded);
            };
        }
        return () => {};
    }, [onAbleToProvideData, onTrackHasEnded, onUnableToProvideData, stream]);
    useEffect(() => {
        setContextState(audioContext.state);
    }, [audioContext]);
    useEffect(() => {
        if (canvas.current) {
            const canvasCtx = getCanvasContext(canvas.current);
            rendererRef.current =
                shape === VmShape.VM_CIRCLE
                    ? new CircleRenderer(canvasCtx, {
                          width,
                          height,
                          shape,
                      })
                    : new BlockRenderer(canvasCtx, {
                          width,
                          height,
                          shape,
                          blocks,
                      });
        }
    }, [blocks, height, shape, width]);
    useEffect(() => {
        if (rendererRef.current) {
            animatorRef.current = new Animator(audioContext, rendererRef.current);
        }
        // intentionally not using enabled in the dependencies here - only want this updating once
    }, [audioContext]);
    useEffect(() => {
        if (animatorRef.current) {
            animatorRef.current.changeStream(stream);
            if (enabled) {
                animatorRef.current.start();
            } else {
                animatorRef.current.stop();
            }
        }
    }, [enabled, stream]);
    const track = stream?.getAudioTracks()?.[0];
    const trackCount = stream?.getAudioTracks().length || 0;
    // prettier-ignore
    const error = !enabled ? ("Disabled") :
        !track ? ("No Audio Input detected") :
            trackCount !== 1 ? (`There are ${trackCount} tracks`) :
                unableToProvideData ? ("Audio Input halted") :
                    !trackEnabled ? (unmuteButton()) :
                        hasEnded ? ('The input device no longer provides audio') :
                            null;
    return React.createElement(
        StyledVolumeMeter,
        { width: width, height: height, className },
        contextState !== 'running' &&
            activateButton(() => audioContext.resume().then(() => setContextState(audioContext.state))),
        React.createElement(MeterDisplay, {
            show: contextState === 'running',
            className: 'volume-meter',
            ref: canvas,
            width: width,
            height: height,
        }),
        contextState === 'running' && error && React.createElement(Alert, { height: height }, error)
    );
};
