import React from 'react';
import cx from 'classnames';
import styles from './MyQuizLogo.module.scss';
import gameState from '../stores/State.js';
import { isFound, isMissing, useRootFirebase } from '../firebase/firebase-storage';

export default function MyQuizLogo({ className, gameId = gameState.gameId }) {
    const [logo, , logoStatus] = useRootFirebase(`games/${gameId}/gameData/settings/logo`, '');
    let src;
    if (isMissing(logoStatus)) {
        src = 'https://remote-quiz-1.s3.us-east-1.amazonaws.com/images/logo.png';
    } else if (isFound(logoStatus)) {
        src = logo;
    } else {
        src = null;
    }
    return <div className={cx(className, styles.main)}>{src && <img alt='logo' src={src} />}</div>;
}
