import React from 'react';
import cx from 'classnames';
import styles from './NoGame.module.scss';
import Flex from '../common/Flex';
import MyQuizLogo from '../components/MyQuizLogo';
import LanguageWrapper, { getString } from '../components/LanguageWrapper';
import { LangArea } from '../common/consts';
import { observer } from 'mobx-react-lite';

export default observer(function NoGame({ className }) {
    const text = getString('nogame.link', LangArea.GAME);
    return (
        <LanguageWrapper flex column center className={cx('bg-pub', styles.main)}>
            <Flex column center className={cx('container-part', styles.container)}>
                <MyQuizLogo />
                <div>{text}</div>
            </Flex>
        </LanguageWrapper>
    );
});
