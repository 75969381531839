import React from 'react';
import styles from './TextBox.module.scss';
import cx from 'classnames';

export default function TextBox({
    placeholder,
    value,
    readOnly,
    className,
    onInput,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    disabled,
    type,
    textarea,
}) {
    const props = {
        className: cx(styles.input, className, { [styles.readOnly]: readOnly }),
        value: value,
        onInput: onInput,
        onChange: onChange,
        onFocus: onFocus,
        onBlur: onBlur,
        onKeyDown: onKeyDown,
        placeholder: placeholder,
        readOnly: readOnly,
        disabled: disabled,
        type: type,
    };
    return textarea ? <textarea {...props} /> : <input {...props} />;
}
