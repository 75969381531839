import {
    isMissing,
    changeValue,
    getValue,
    isPending,
    firebaseGameRoot,
    onValue,
    firebaseAbsoluteRoot,
} from './firebase-storage';
import { PENDING_VALUE, MISSING_VALUE, FOUND_VALUE } from './firebase-consts';
import _ from 'lodash';
import { RoundMode } from '../common/consts';
import { openConfirmation } from '../components/dialogs/Confirmation';
import { generateCode, getHashCode, getHashCodePerLetter } from '../common/utils';

export async function getRoundName(roundId) {
    const roundName = await getValue(`gameData/rounds/${roundId}/name`);
    if (roundName) {
        return [roundName, FOUND_VALUE];
    }
    const roundsOrder = (await getValue('gameData/roundOrder')) || [];
    const roundNumber = roundsOrder.indexOf(roundId) + 1;
    return [`סיבוב ${roundNumber}`, MISSING_VALUE];
}

export async function changeCurrentRound(roundId, { confirm = true } = {}) {
    const currentRound = await getValue(`gameData/currentRound`, null);
    if (roundId === currentRound) {
        return;
    }
    let content = 'האם אתה בטוח שברצונך להפסיק סיבוב זה?';
    if (roundId) {
        const [roundName] = await getRoundName(roundId);
        content = `האם אתה בטוח שברצונך לעבור ל '${roundName}'?`;
    }
    if (confirm) {
        await openConfirmation({ danger: !roundId, content, title: roundId ? `התחל סיבוב` : `עצור סיבוב` });
    }
    const questions = await getValue(`gameData/rounds/${roundId}/questions`, []);
    await Promise.all([exposeAllQuestions(questions, false), exposeAllAnswers(questions, false)]);
    changeValue(`gameData/currentRound`, roundId);
    changeValue(`gameData/currentRoundMode`, RoundMode.OPEN);
}

export async function removeRound(roundId, isLiveRound) {
    const roundData = (await getValue(`gameData/rounds/${roundId}/`)) || {};
    changeValue(`gameData/rounds/${roundId}`, null);
    if (isLiveRound) {
        changeCurrentRound(null, { confirm: false });
    }
    (roundData.questions || []).forEach((questionId) => removeQuestion(questionId));
}

export async function removeQuestion(questionId) {
    return changeValue(`gameData/questions/${questionId}`, null);
}

export async function getGroupLockedAnswersForRoundOnce({ groupId, roundId }) {
    return (await getValue(`lockedAnswers/${groupId}/${roundId}/questions`)) || {};
}

export function getScore(scoreValue) {
    if (scoreValue == null) {
        return null;
    }
    if (Number(scoreValue) === 0) {
        return 0;
    }
    if (Number(scoreValue) === 1) {
        return 1;
    }
    if (Number(scoreValue) === 0.5) {
        return 0.5;
    }
    return null;
}

export async function getCheckingGroupIdOnce(groupId, roundId) {
    const [allGroupsIds, lockedGroupsAnswers] = await Promise.all([getValue(`groups`), getValue(`lockedAnswers`)]);

    // disabled group will see its own answers
    if (allGroupsIds[groupId]?.disabled) {
        return groupId;
    }
    if (!allGroupsIds[groupId]) {
        return groupId;
    }

    const sorted = Object.keys(allGroupsIds || {}).sort();
    const filtered =
        sorted.filter((otherGroupId) => {
            if (!allGroupsIds[otherGroupId]) {
                return false;
            }
            // filter out disabled groups
            if (allGroupsIds[otherGroupId].disabled) {
                return false;
            }
            return (
                otherGroupId === groupId ||
                Object.keys((lockedGroupsAnswers || {})?.[otherGroupId]?.[roundId]?.questions || {}).length >= 0
            );
        }) || [];
    return filtered[(filtered.indexOf(groupId) + 1) % filtered.length];
}

export async function getRoundQuestionsOrderOnce({ roundId }) {
    return await getValue(`gameData/rounds/${roundId}/questions`, []);
}

export async function getLockedGroupAnswersOnce({ groupId, roundId }) {
    return await getValue(`lockedAnswers/${groupId}/${roundId}/questions`, {});
}

export async function getLiveGroupAnswersOnce({ groupId, roundId }) {
    return await getValue(`groupsAnswers/${groupId}/${roundId}/questions`, {});
}

export async function getGroupScoredAnswersOnce({ groupId, roundId }) {
    return await getValue(`scoredAnswers/${groupId}/rounds/${roundId}/questions`, {});
}

export async function getCorrectCodeForRound({ roundId }) {
    const roundQuestionOrder = await getRoundQuestionsOrderOnce({ roundId });
    const correctAnswers = await getValue(`gameData/correctAnswers`, {});
    return generateCode({ answers: correctAnswers, questionsOrder: roundQuestionOrder });
}

export async function getLiveGroupCodeForRound({ roundId, groupId }) {
    const roundQuestionOrder = await getRoundQuestionsOrderOnce({ roundId });
    const groupAnswers = await getLiveGroupAnswersOnce({ groupId, roundId });
    return generateCode({ answers: groupAnswers, questionsOrder: roundQuestionOrder });
}

export function aggregate(useFirebaseResults = {}) {
    const values = _.mapValues(useFirebaseResults, (result) => result?.[0]);
    const callbacks = _.mapValues(useFirebaseResults, (result) => result?.[1]);
    if (Object.entries(useFirebaseResults).some(([, result]) => isPending(result?.[2]))) {
        return [values, callbacks, PENDING_VALUE];
    }
    if (Object.entries(useFirebaseResults).every(([, result]) => isMissing(result?.[2]))) {
        return [values, callbacks, MISSING_VALUE];
    }
    return [values, callbacks, FOUND_VALUE];
}

export async function deleteGroup({ groupId }) {
    changeValue(`groups/${groupId}`, null);
    changeValue(`groupsAnswers/${groupId}`, null);
    changeValue(`scoredAnswers/${groupId}`, null);
    changeValue(`lockedAnswers/${groupId}`, null);
    changeValue(`lockedIndicators/${groupId}`, null);
}

let disconnectDisposers;
export function hangDisconnectListeners({ userId }) {
    disconnectDisposers && disconnectDisposers();
    firebaseGameRoot(`/activeParticipants/${userId}`).onDisconnect().set(null);
    disconnectDisposers = () => {
        firebaseGameRoot(`/activeParticipants/${userId}`).onDisconnect().cancel();
    };
}

export async function removeUserId({ userId }) {
    return registerUserToGroup({ userId, destGroupId: null });
}

export async function lockUserId({ userId }) {
    return registerUserToGroup({ userId, destGroupId: 'banned' });
}

export async function registerUserToGroup({ userId, destGroupId = null }) {
    hangDisconnectListeners({ userId });
    return changeValue(`activeParticipants/${userId}`, destGroupId);
}

export async function calculateCodeAndStore() {
    const roundsOrder = await getValue(`gameData/roundOrder`, []);
    const rounds = await getValue(`gameData/rounds`, {});
    const promisesArray = roundsOrder.map(async (roundId) => {
        if (rounds[roundId]?.hasCode) {
            const code = await getCorrectCodeForRound({ roundId });
            await changeValue(`gameData/correctCodes/${roundId}`, {
                code,
                hash: getHashCode(code),
                byLetter: getHashCodePerLetter(code, roundId),
            });
        } else {
            await changeValue(`gameData/correctCodes/${roundId}`, null);
        }
    });
    return await Promise.all(promisesArray);
}

export async function checkSubmittedLiveCode({ roundId, groupId }) {
    const correctCodeHash = await getValue(`gameData/correctCodes/${roundId}/hash`, '');
    if (!correctCodeHash) {
        return true;
    }
    const groupCode = await getLiveGroupCodeForRound({ roundId, groupId });
    return correctCodeHash === getHashCode(groupCode);
}

function getDiffTime(remainingTime, totalTime) {
    if (!remainingTime || !totalTime) {
        return 0;
    }
    let remainingTimeNum = 0,
        totalTimeNum = 0;
    try {
        remainingTimeNum = parseInt(remainingTime);
        totalTimeNum = parseInt(totalTime);
    } catch (err) {
        return 0;
    }
    return totalTimeNum - remainingTimeNum;
}

export async function lockGroupAnswers({ groupId, roundId, timesUp = false, selflock = false }) {
    const [groupAnswers, lockedIndicator] = await Promise.all([
        getValue(`groupsAnswers/${groupId}/${roundId}`, {}),
        getValue(`lockedIndicators/${groupId}/${roundId}`, false),
    ]);
    if (lockedIndicator) {
        return;
    }
    const remainingTime = await getValue(`gameData/timer`, 0);
    const totalTime = await getValue(`gameData/rounds/${roundId}/totalTime`, 0);
    let submitTime;
    if (timesUp) {
        submitTime = totalTime;
    } else {
        submitTime = getDiffTime(remainingTime, totalTime);
    }

    return Promise.all([
        changeValue(`lockedAnswers/${groupId}/${roundId}`, { questions: groupAnswers.questions || {}, submitTime }),
        changeValue(`scoredAnswers/${groupId}/rounds/${roundId}/submitTime`, submitTime),
        changeValue(`scoredAnswers/${groupId}/rounds/${roundId}/totalTime`, totalTime),
        selflock && changeValue(`lockedIndicators/${groupId}/${roundId}`, true),
    ]);
}

export async function removeGroupSelfUnlock({ groupId, roundId }) {
    return changeValue(`lockedIndicators/${groupId}/${roundId}`, null);
}

export async function clearGroupLockedAnswers({ groupId, roundId }) {
    changeValue(`lockedAnswers/${groupId}/${roundId}`, null);
}

export async function lockAllGroupAnswers({ roundId }) {
    const groups = await getValue(`groups`);

    return Promise.all(
        Object.keys(groups).map(async (groupId) => {
            return lockGroupAnswers({ groupId, roundId, timesUp: true });
        })
    );
}

export async function exposeAllQuestions(questionsOrder, expose) {
    return await Promise.all(
        questionsOrder.map((questionId) => {
            return changeValue(`gameData/questions/${questionId}/showQuestion`, expose);
        })
    );
}

export async function exposeAllAnswers(questionsOrder, expose) {
    return await Promise.all(
        questionsOrder.map((questionId) => {
            return changeValue(`gameData/correctAnswers/${questionId}/showAnswer`, expose);
        })
    );
}

export async function clearAllResults() {
    await Promise.all([
        changeValue(`scoredAnswers`, {}),
        changeValue(`lockedAnswers`, {}),
        changeValue(`groupsAnswers`, {}),
        changeValue(`lockedIndicators`, {}),
    ]);
}

export async function onPlayersValue(callback, options = {}) {
    return onValue('players', callback, { firebaseRootRefProvider: firebaseAbsoluteRoot, ...options });
}

export async function inactivateGame() {
    await changeCurrentRound(null);
    await changeQuizmasterBroadcast(null);
}

export async function changeQuizmasterBroadcast(uid) {
    return changeValue('gameData/quizmasterBroadcast', uid);
}
