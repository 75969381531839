import { observable, computed, action, autorun } from 'mobx';
import { onValue } from '../firebase/firebase-storage';
import { initAuthListener } from '../firebase/firebase-auth';
import { onPlayersValue } from '../firebase/firebase-helpers';

const USER_AUTH_STORAGE_KEY = 'user';
const PLAYER_STORAGE_KEY = 'player';

export class User {
    id;
    name;
    email;
    photo;

    constructor(userCredential) {
        this.id = userCredential.uid;
        this.name = userCredential.displayName;
        this.email = userCredential.email;
        this.photo = userCredential.photoURL;
    }
}

export class Player {
    displayName;
    photo;

    constructor(user) {
        this.displayName = user.name;
        this.photo = user.photo || 'placeholder';
    }
}

class State {
    @observable gameId;
    @observable groupId;
    @observable userAuth = parse(localStorage.getItem(USER_AUTH_STORAGE_KEY));
    @observable currentPlayer = parse(localStorage.getItem(PLAYER_STORAGE_KEY));
    @observable isAdmin;
    @observable quizSrc;
    @observable groupNames = {};
    @observable players = {};
    @observable isAuthInit;
    @observable inSyncWithDb;
    @observable timerDifference = 0;

    @action async hangListeners() {
        onValue(`groups`, (value) => {
            const groupNames = {};
            Object.entries(value || {}).forEach(([groupId, groupData]) => {
                groupNames[groupId] = groupData?.displayName?.value + (groupData?.disabled ? '*' : '');
            });
            this.setGroupNames(groupNames);
        });

        autorun(() => {
            if (this.userId) {
                onValue(`activeParticipants/${this.userId}`, (value) => {
                    if (value === this.groupId) {
                        this.inSyncWithDb = true;
                    } else {
                        this.inSyncWithDb = false;
                    }
                });
            } else {
                this.inSyncWithDb = true;
            }
        });

        initAuthListener();

        onPlayersValue((value) => {
            const players = {};
            Object.entries(value || {}).forEach(([playerId, playerData]) => {
                players[playerId] = playerData;

                if (playerId === this.userId) {
                    this.setCurrentPlayer(playerData);
                }
            });
            this.setPlayers(players);
        });
    }

    @computed get isAuthenticated() {
        return !!this.userAuth;
    }

    @computed get userEmail() {
        return this.userAuth?.email;
    }

    @computed get userPhoto() {
        return this.currentPlayer?.photo;
    }

    @computed get displayName() {
        return this.currentPlayer?.displayName;
    }

    @computed get userId() {
        return this.userAuth?.id;
    }

    @computed get channel() {
        return this.groupId;
    }

    @action setDisplayName(name) {
        this.currentPlayer.displayName = name;
    }

    @action setUserPhoto(photo) {
        this.currentPlayer.photo = photo;
    }

    @action setGameId(gameId) {
        this.gameId = gameId;
    }

    @action setGroupId(groupId) {
        this.groupId = groupId;
    }

    @action clearGroup() {
        this.groupId = null;
    }

    @action setQuizSrc(quizSrc) {
        this.quizSrc = quizSrc;
    }

    @action setUserAuth(user) {
        localStorage.setItem(USER_AUTH_STORAGE_KEY, JSON.stringify(user));
        this.userAuth = user;
    }

    @action async setCurrentPlayer(player) {
        localStorage.setItem(PLAYER_STORAGE_KEY, JSON.stringify(player));
        this.currentPlayer = player;
    }

    @action initAuth() {
        this.isAuthInit = true;
    }

    @action clearUser() {
        localStorage.removeItem(USER_AUTH_STORAGE_KEY);
        this.user = null;
        this.setAdmin(false);
    }

    @action clearCurrentPlayer() {
        localStorage.removeItem(PLAYER_STORAGE_KEY);
        this.currentPlayer = null;
    }

    @action setAdmin(isAdmin) {
        this.isAdmin = isAdmin;
    }

    @action setGroupNames(groupNames) {
        this.groupNames = groupNames;
    }

    @action setPlayers(players) {
        this.players = players;
    }

    getGroupName(groupId) {
        return this.groupNames[groupId] || groupId;
    }

    getPlayerDisplayName(playerId) {
        return this.players[playerId]?.displayName || playerId;
    }

    @action setTimerDifference(timerDifference = 0) {
        const intTimerDifference = 1 * timerDifference;
        if (Number.isNaN(intTimerDifference) || !intTimerDifference) {
            this.timerDifference = 0;
        } else {
            this.timerDifference = intTimerDifference;
        }
    }
}

export default new State();

function parse(value) {
    try {
        return JSON.parse(value);
    } catch (err) {
        return undefined;
    }
}
