import React from 'react';
import cx from 'classnames';
import styles from './MainErrorBoundary.module.scss';
import LanguageWrapper, { useLanguageContext } from '../components/LanguageWrapper';
import MyQuizLogo from '../components/MyQuizLogo';
import Flex from '../common/Flex';

export default function MainErrorBoundary({ className }) {
    return (
        <LanguageWrapper flex column center className={cx('bg-pub', styles.main)}>
            <MyQuizLogo />
            <ErrorInner />
        </LanguageWrapper>
    );
}

function ErrorInner() {
    const { str } = useLanguageContext();
    return (
        <Flex column center className={cx('container-part', styles.container)}>
            <div>{str('general.error')}</div>
        </Flex>
    );
}
