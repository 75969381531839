// roundQuestions: useRoundQuestions({ roundId }),
// scoredResults: useScoredGroupResultsForRound({ groupId, roundId }),
// correctCode: useCorrectCodeForRound({ roundId }),

import { calculateTimeScore } from '../../common/utils';
import _ from 'lodash';

export function getTimeScore({ scoredResults = {} } = {}, { calcAbsoluteTimePoints = true } = {}) {
    const actualTime = scoredResults?.submitTime || 0;
    const totalTime = scoredResults?.totalTime || 0;
    const [score, actualTimeResult, totalTimeResult] = calculateTimeScore(actualTime, totalTime, {
        calcAbsoluteTimePoints,
    });
    return [1 * score, actualTimeResult, totalTimeResult];
}

export function getQuestionsScore({ roundQuestions = [], scoredResults = {} } = {}) {
    return roundQuestions
        .map((questionId) => {
            const s = getFinalScoreFromScoreObject(scoredResults?.questions?.[questionId]);
            return s ? Number(s) : 0;
        })
        .reduce((acc, cur) => acc + cur, 0);
}

export function getQuizmasterScore({ scoredResults = {} }) {
    return 1 * (scoredResults.quizmasterScore || 0);
}

export function getFinalScoreFromScoreObject(scoreObject) {
    const quizmasterScore = scoreObject?.quizmasterScore;
    // eslint-disable-next-line eqeqeq
    if (quizmasterScore == undefined || quizmasterScore === '') {
        return scoreObject?.score;
    } else {
        return Number(quizmasterScore);
    }
}

export function getTotalScore({ roundQuestions, scoredResults }, { calcAbsoluteTimePoints = true } = {}) {
    const quizmasterScore = getQuizmasterScore({ scoredResults });
    const questionsScore = getQuestionsScore({ roundQuestions, scoredResults });
    const [timeScore, actualTime, totalTime] = getTimeScore({ scoredResults }, { calcAbsoluteTimePoints });
    const res = timeScore + questionsScore + quizmasterScore;
    return [res, actualTime, totalTime];
}

export function getGameScoreForGroup(
    { roundOrder = [], scoredAnswersForGroup = {}, rounds = {} },
    { calcAbsoluteTimePoints = true } = {}
) {
    const resultsArray = roundOrder.map((roundId) => {
        const roundQuestions = rounds[roundId]?.questions || [];
        const scoredResults = scoredAnswersForGroup[roundId];
        const result = getTotalScore({ roundQuestions, scoredResults }, { calcAbsoluteTimePoints });
        return result;
    });
    const res = resultsArray.reduce((acc, cur) => acc + cur[0], 0);
    const accumulatedGroupTime = resultsArray.reduce((acc, cur) => acc + cur[1], 0);
    const roundPoints = _.reverse(resultsArray.map((result) => 1 * result[0]).sort((a, b) => a - b)).reduce(
        (acc, cur) => {
            let curNum = cur ? 1 * cur : 0;
            curNum = curNum === 10 ? 9.9 : curNum;
            return 100 * acc + 10 * (curNum || 0);
        },
        0
    );
    return { score: res.toFixed(2), accumulatedGroupTime, roundPoints };
}
