import React, { useState, useCallback } from 'react';
import FilePicker from '../components/FilePicker';
import styles from './UserProfile.module.scss';
import TextBox from '../components/TextBox';
import Modal from '../components/Modal';
import Badge from '@material-ui/core/Badge';
import UserIcon from '../components/UserIcon';

export default function UserProfile({ userId, initialDisplayName, initialImageUrl, onNameChange, onPhotoChange }) {
    const [openPicker, setOpenPicker] = useState(false);
    const [imageUrl, setImageUrl] = useState(initialImageUrl);
    const [name, setName] = useState(initialDisplayName);

    const onSave = useCallback(() => {
        onPhotoChange(imageUrl);
        setOpenPicker(false);
    }, [onPhotoChange, imageUrl, setOpenPicker]);

    return (
        <div className={styles.main}>
            <Badge
                overlap='circle'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={
                    <>
                        <Modal title='בחר תמונה' open={openPicker} onClose={() => setOpenPicker(false)} onSave={onSave}>
                            <TextBox
                                className={styles.editIcon}
                                placeholder='הזן כתובת URL'
                                value={imageUrl}
                                onInput={(event) => setImageUrl(event.target.value)}
                            />
                            <span className={styles.separator}>או</span>
                            <FilePicker
                                folder='users'
                                className={styles.filePicker}
                                onUpload={(fileUrl) => setImageUrl(fileUrl)}
                            />
                        </Modal>
                    </>
                }
            >
                <div className={styles.userIcon}>
                    <UserIcon
                        size='120px'
                        forcedIcon={imageUrl}
                        userId={userId}
                        key={`userimg_${userId}`}
                        onClick={() => setOpenPicker(true)}
                    />
                </div>
            </Badge>

            <div className={styles.name}>
                <TextBox
                    value={name}
                    onInput={(event) => {
                        setName(event.target.value);
                        onNameChange(event.target.value);
                    }}
                />
            </div>
        </div>
    );
}
