import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import gameState from '../stores/State';
import { buildPath, LOGIN, GAME_PATH_PARAM } from '../routes';

export default function MemberRoute({ children, component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                gameState.isAuthenticated ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: buildPath(LOGIN, { [GAME_PATH_PARAM]: gameState.gameId }),
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
