import { GameId } from './common/consts';

export const GROUP_PATH_PARAM = ':groupId';
export const GAME_PATH_PARAM = ':gameId';
export const ADMIN_PART = '_admin';
export const GENERAL = 'general';

export const MAIN_ADMIN = `/${GENERAL}/${ADMIN_PART}`;
export const ADMIN = `/${GAME_PATH_PARAM}/${ADMIN_PART}`;
export const SCORES = `/${GAME_PATH_PARAM}/${ADMIN_PART}/scores`;
export const DEVELOPERS = `/${GAME_PATH_PARAM}/${ADMIN_PART}/developers`;
export const GROUPS = `/${GAME_PATH_PARAM}/${ADMIN_PART}/groups`;
export const GROUP = `/${GAME_PATH_PARAM}/group/${GROUP_PATH_PARAM}`;
export const LOGIN = `/${GAME_PATH_PARAM}/login`;
export const SETTINGS = `/mediasettings`;

export function buildPath(path = '/', replacers = {}) {
    let pathResult = path;
    Object.entries(replacers).forEach(([key, value]) => {
        pathResult = pathResult.replace(key, value);
    });
    return pathResult;
}

export function getGameIdFromRoute(route) {
    if (route === LOGIN || route === SETTINGS) {
        return GameId.NO_SPECIFIC_GAME;
    }
    let gameId = null;
    try {
        const matcher = route.match(/\/(.*?)\//)[1];
        gameId = matcher;
    } catch (err) {}
    return gameId;
}
