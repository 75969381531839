import { getAuth } from './firebase-storage';
import { signIn, signOut } from '../services/authService';

export function initAuthListener() {
    return getAuth().onAuthStateChanged(function (user) {
        if (user) {
            signIn({ user });
        } else {
            signOut();
        }
    });
}

export function signOutOfFirebase() {
    getAuth().signOut();
}
