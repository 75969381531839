import { EventEmitter } from 'events';
import { setupAnalyzer } from './functions';
const getVolume = (analyser) => {
    const buckets = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(buckets);
    return buckets.reduce((a, b) => a + b) / analyser.frequencyBinCount / 128;
};
export class Animator extends EventEmitter {
    constructor(ctx, renderer) {
        super();
        this.rafId = 0;
        this.isStopping = false;
        this.ctx = ctx;
        this.renderer = renderer;
    }
    changeStream(s) {
        const { isStopping } = this;
        this.stop();
        if (s) {
            const stream = s;
            var _a;
            if (stream.id === ((_a = this.stream) === null || _a === void 0 ? void 0 : _a.id)) {
                if (!isStopping) {
                    this.start();
                }
            } else {
                this.stream = stream;
                const a = setupAnalyzer({ audioContext: this.ctx, stream });
                a.minDecibels = -100;
                a.maxDecibels = -30;
                a.fftSize = 64;
                this.analyser = a;
            }
        }
    }
    start() {
        this.renderer.start();
        this.isStopping = false;
        this.emit('start');
        const drawLoop = () => {
            if (this.isStopping || !this.analyser) {
                this.isStopping = false;
                return;
            }
            const volume = getVolume(this.analyser);
            this.renderer.draw(volume);
            this.rafId = window.requestAnimationFrame(drawLoop);
        };
        drawLoop();
    }
    stop() {
        this.emit('stop');
        this.renderer.stop();
        this.isStopping = true;
        window.cancelAnimationFrame(this.rafId);
    }
}
