import React from 'react';
export default function Flex({
    children,
    column,
    align,
    direction,
    justify,
    center,
    className,
    full,
    style = {},
    ...rest
}) {
    const flexDirection = direction || (column ? 'column' : undefined);
    style.flexDirection = style.flexDirection || flexDirection;
    style.alignItems = style.alignItems || align || (center ? 'center' : undefined);
    style.justifyContent = style.justifyContent || justify || (center ? 'center' : undefined);
    if (full) {
        style.width = '100%';
        style.height = '100%';
    }
    return (
        <div className={className} style={{ display: 'flex', ...style }} {...rest}>
            {children}
        </div>
    );
}
