import enums from 'enumish';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import gameState from '../stores/State';
import { PENDING_VALUE, MISSING_VALUE, FOUND_VALUE } from './firebase-consts';
import { generateId } from '../common/utils';

export function init() {
    const firebaseConfig = JSON.parse(
        atob(
            'eyJhcGlLZXkiOiJBSXphU3lCV0EzUnZlSUo3dnRaM0JtbWZqREs2VmRsXzYxOGNEYmMiLCJhdXRoRG9tYWluIjoicmVtb3RlLXF1aXotZmQ5NDAuZmlyZWJhc2VhcHAuY29tIiwiZGF0YWJhc2VVUkwiOiJodHRwczovL3JlbW90ZS1xdWl6LWZkOTQwLmZpcmViYXNlaW8uY29tIiwicHJvamVjdElkIjoicmVtb3RlLXF1aXotZmQ5NDAiLCJzdG9yYWdlQnVja2V0IjoicmVtb3RlLXF1aXotZmQ5NDAuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjI3MzE2OTUyOTUxOSIsImFwcElkIjoiMToyNzMxNjk1Mjk1MTk6d2ViOmY1OGEzNjU1MDhhODFlZjE3OWIyNWEifQ=='
        )
    );
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
}

export function useRootFirebase(key, initialValue, options = {}) {
    return useFirebase(key, initialValue, { root: true, ...options });
}

export function useFirebase(
    key,
    initialValue,
    {
        defaultValue = initialValue,
        type = ChangeMode.SET,
        converter,
        root = false,
        firebaseRootRefProvider = root ? firebaseAbsoluteRoot : firebaseGameRoot,
    } = {}
) {
    const writeKey = typeof key === 'string' ? key : key.writeKey;
    const readKey = typeof key === 'string' ? key : key.readKey;

    const [valueObject, setValueObject] = useState({ value: initialValue, status: PENDING_VALUE });
    const [defaultVal] = useState(defaultValue);

    useEffect(() => {
        const unsubscribe = onValue(
            readKey,
            (snapshotVal) => {
                let status, val;
                if (snapshotVal === null || snapshotVal === undefined) {
                    status = MISSING_VALUE;
                    val = defaultVal;
                } else {
                    status = FOUND_VALUE;
                    val = snapshotVal;
                }
                setValueObject({ value: converter ? converter(val) : val, status });
            },
            { root, firebaseRootRefProvider }
        );
        return unsubscribe;
    }, [readKey, defaultVal, converter, root, firebaseRootRefProvider]);

    const changeFirebaseValue = useCallback(
        (newValue, writeTo = writeKey) => {
            return changeValue(writeTo, newValue, type, { root, firebaseRootRefProvider });
        },
        [writeKey, type, firebaseRootRefProvider, root]
    );

    return [valueObject.value, changeFirebaseValue, valueObject.status];
}

export function onValue(
    key,
    callback,
    { root = false, firebaseRootRefProvider = root ? firebaseAbsoluteRoot : firebaseGameRoot } = {}
) {
    try {
        const unsubscribe = firebaseRootRefProvider()
            .child(key)
            .on('value', (snapshot) => {
                callback(snapshot?.val(), snapshot);
            });
        return unsubscribe;
    } catch (err) {
        console.error(err);
        callback(null);
        return () => {};
    }
}

export async function getValue(
    key,
    defaultValue = null,
    { withSnapshot, root = false, firebaseRootRefProvider = root ? firebaseAbsoluteRoot : firebaseGameRoot } = {}
) {
    const snapshot = await firebaseRootRefProvider().child(key).once('value');
    const val = snapshot?.val() ?? defaultValue;
    return withSnapshot ? [val, snapshot] : val;
}

export const ChangeMode = enums(['PUSH', 'SET', 'UPDATE']);

export function changeValue(
    key,
    newValue,
    type = ChangeMode.SET,
    { root = false, firebaseRootRefProvider = root ? firebaseAbsoluteRoot : firebaseGameRoot } = {}
) {
    try {
        const ref = firebaseRootRefProvider().child(key);
        if (type === ChangeMode.PUSH) {
            return ref.push(newValue);
        }
        if (type === ChangeMode.UPDATE) {
            return ref.update(newValue);
        }
        return ref.set(newValue);
    } catch (err) {
        console.error(err);
    }
}

export function firebaseGameRoot(ref = '') {
    return gameState.gameId ? firebase.database().ref(`games/${gameState.gameId}${ref}`) : null;
}

export function firebaseAbsoluteRoot(ref = '') {
    return gameState.gameId ? firebase.database().ref(ref ? `${ref}` : undefined) : null;
}

export function getAuth() {
    return firebase.auth();
}

export async function uploadFile(file, { folder = 'general', onProgress } = {}) {
    const [fileName, fileExtension] = file.name.split('.');
    const uniqueFileName = `${fileName}_${generateId(3)}.${fileExtension}`;
    const firebaseRef = () => firebase.storage().ref(`files_mq/${folder}`).child(uniqueFileName);
    const uploadTask = firebaseRef().put(file);
    return new Promise((resolve) =>
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                onProgress && onProgress(snapshot.bytesTransferred, snapshot.totalBytes);
            },
            (error) => console.log(error),
            () => {
                firebaseRef()
                    .getDownloadURL()
                    .then((url) => {
                        try {
                            resolve(url);
                        } catch (err) {
                            resolve(url);
                        }
                    });
            }
        )
    );
}

export function isPending(...values) {
    return values.some((value) => status(value) === PENDING_VALUE);
}

export function isMissing(value) {
    return status(value) === MISSING_VALUE;
}

export function isFound(value) {
    return status(value) === FOUND_VALUE;
}
export function isValid(value) {
    return !isPending(value);
}
export const READ_ONLY = undefined;

export function getServerTimeStamp() {
    return firebase.database.ServerValue.TIMESTAMP;
}

export function incrementValue(incBy = 1) {
    return firebase.database.ServerValue.increment(incBy);
}

function status(value) {
    return value?.status ?? value;
}

export function logData(data = {}) {
    data.timestamp = getServerTimeStamp();
    Object.entries(data).forEach(([key, value]) => {
        if (value === undefined) {
            data[key] = null;
        }
    });
    changeValue(`logs/${gameState.gameId || 'none'}`, data, ChangeMode.PUSH, { root: true });
}

init();
