import _ from 'lodash';
import {
    useFirebase,
    isPending,
    isMissing,
    isFound,
    READ_ONLY,
    firebaseAbsoluteRoot,
    getValue,
} from './firebase-storage';
import { PENDING_VALUE, MISSING_VALUE, FOUND_VALUE } from './firebase-consts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { changeCurrentRound, aggregate } from './firebase-helpers';
import {
    generateCode,
    getGroupReadOnlyDisplayName,
    getGroupCurrentParticipants,
    getLetterStatus,
    filterDemoRounds,
} from '../common/utils';
import { RoundMode, ScoreBoardType, MarkerType } from '../common/consts';
import { getGameScoreForGroup } from '../admin/scores/scoringUtils';

export function useCurrentRoundData() {
    let roundId, roundMode, roundNumber, roundDataStatus;
    const [currentRoundId, , currentRoundIdStatus] = useFirebase('gameData/currentRound', '');
    const [currentRoundMode, , currentRoundModeStatus] = useFirebase('gameData/currentRoundMode', RoundMode.OPEN);
    const [roundOrder, , roundOrderStatus] = useFirebase('gameData/roundOrder', []);
    const changeRoundId = useCallback((roundId) => {
        changeCurrentRound(roundId);
    }, []);
    roundId = currentRoundId;
    roundMode = currentRoundMode;
    roundNumber = 0;
    if (isPending(currentRoundIdStatus) || isPending(currentRoundModeStatus) || isPending(roundOrderStatus)) {
        roundDataStatus = PENDING_VALUE;
    } else if (isMissing(currentRoundIdStatus)) {
        roundDataStatus = MISSING_VALUE;
    } else {
        roundNumber = roundOrder.indexOf(currentRoundId) + 1;
        if (roundNumber === 0) {
            roundDataStatus = MISSING_VALUE;
        } else {
            roundDataStatus = FOUND_VALUE;
        }
    }

    return [{ roundId, roundMode, roundNumber }, changeRoundId, roundDataStatus];
}

export function useRoundName(roundId, { addAsterixForDemoRounds = false } = {}) {
    const [roundName, , roundNameStatus] = useFirebase(`gameData/rounds/${roundId}/name`, '');
    const [demoRound, , demoRoundStatus] = useFirebase(`gameData/rounds/${roundId}/demoRound`, false);
    const [roundsOrder, , roundsOrderStatus] = useFirebase(`gameData/roundOrder`, []);
    let value = '',
        status = PENDING_VALUE;
    if (!addAsterixForDemoRounds || !isPending(demoRoundStatus)) {
        if (isFound(roundNameStatus)) {
            value = roundName;
            status = FOUND_VALUE;
        } else if (isMissing(roundNameStatus) && !isPending(roundsOrderStatus)) {
            const roundNumber = roundsOrder.indexOf(roundId) + 1;
            value = `סיבוב ${roundNumber}`;
            status = MISSING_VALUE;
        }
        if (addAsterixForDemoRounds && demoRound) {
            value = value + ' *';
        }
    }
    return [value, READ_ONLY, status];
}

export function useGroupAnswer({ groupId, questionId, roundId }) {
    return useFirebase(`groupsAnswers/${groupId}/${roundId}/questions/${questionId}/value`);
}

export function useGroupLockedAnswersForRound({ groupId, roundId }) {
    return useFirebase(`lockedAnswers/${groupId}/${roundId}/questions`, {});
}

export function useGroupLockedResultsForRound({ groupId, roundId }) {
    return useFirebase(`lockedAnswers/${groupId}/${roundId}`, {});
}

export function useScoredGroupResultsForRound({ groupId, roundId }) {
    return useFirebase(`scoredAnswers/${groupId}/rounds/${roundId}/`, {});
}
export function useScoredAnswersForRound({ groupId, roundId }, ...rest) {
    return useFirebase(`scoredAnswers/${groupId}/rounds/${roundId}/questions/`, ...rest);
}

export function useScoredAnswer({ groupId, roundId, questionId }, ...rest) {
    return useFirebase(`scoredAnswers/${groupId}/rounds/${roundId}/questions/${questionId}`, ...rest);
}

export function useRoundQuestions({ roundId }) {
    return useFirebase(`gameData/rounds/${roundId}/questions`, []);
}

export function useScoringGroups({ roundId }) {
    const [{ groups, lockedAnswers }, , status] = aggregate({
        groups: useGroups(),
        lockedAnswers: useFirebase('lockedAnswers', {}),
    });
    const sorted = Object.keys(groups || {}).sort();
    const filtered =
        sorted.filter((otherGroupId) => {
            if (!groups[otherGroupId]) {
                return false;
            }
            // filter out disabled groups
            if (groups[otherGroupId].disabled) {
                return false;
            }
            return Object.keys((lockedAnswers || {})?.[otherGroupId]?.[roundId]?.questions || {}).length >= 0;
        }) || [];

    const checkingMap = Object.keys(groups).reduce((obj, checkingGroupId) => {
        obj[checkingGroupId] = filtered[(filtered.indexOf(checkingGroupId) + 1) % filtered.length];
        return obj;
    }, {});
    return [checkingMap, READ_ONLY, status];
}

export function useGroups({ includePhantom = false } = {}) {
    const [groups, , status] = useFirebase('groups', {});
    if (includePhantom || !isFound(status)) {
        return [groups, READ_ONLY, status];
    } else {
        return [_.pickBy(groups, (groupData) => groupData && !groupData?.disabled), READ_ONLY, status];
    }
}

export function useSubmittedGroups({ includePhantom = false } = {}) {
    const [{ lockedIndicators, groups }, , status] = aggregate({
        lockedIndicators: useFirebase('lockedIndicators', {}),
        groups: useGroups({ includePhantom }),
    });
    const rounds = {};
    if (isPending(status)) {
        return [{ rounds, groups }, READ_ONLY, PENDING_VALUE];
    }
    Object.entries(groups).forEach(([groupId]) => {
        Object.entries(lockedIndicators[groupId] || {}).forEach(([roundId, areAnswersLocked]) => {
            if (areAnswersLocked) {
                rounds[roundId] = rounds[roundId] || {};
                rounds[roundId][groupId] = true;
            }
        });
    });
    return [{ rounds, groups }, READ_ONLY, FOUND_VALUE];
}

export function useGroupScoringData({ groupId, roundId }) {
    const [scoringData, , status] = aggregate({
        roundQuestions: useRoundQuestions({ roundId }),
        scoredResults: useScoredGroupResultsForRound({ groupId, roundId }),
        groupLockedResults: useGroupLockedResultsForRound({ groupId, roundId }),
    });
    if (isPending(status)) {
        return [{}, READ_ONLY, PENDING_VALUE];
    }
    return [scoringData, READ_ONLY, FOUND_VALUE];
}

export function useGroupCodeForRound({ groupId, roundId }) {
    const [{ roundQuestions, groupAnswers }, , status] = aggregate({
        roundQuestions: useRoundQuestions({ roundId }),
        groupAnswers: useFirebase(`groupsAnswers/${groupId}/${roundId}/questions`, {}),
    });
    if (isPending(status)) {
        return ['', READ_ONLY, PENDING_VALUE];
    }
    const code = generateCode({ answers: groupAnswers, questionsOrder: roundQuestions });
    return [code, READ_ONLY, FOUND_VALUE];
}

export function useCorrectCodeForRound({ roundId }) {
    const [{ roundQuestions, correctAnswers }, , status] = aggregate({
        roundQuestions: useRoundQuestions({ roundId }),
        correctAnswers: useFirebase(`gameData/correctAnswers`, {}),
    });
    if (isPending(status)) {
        return ['', READ_ONLY, PENDING_VALUE];
    }
    const code = generateCode({ answers: correctAnswers, questionsOrder: roundQuestions });
    return [code, READ_ONLY, FOUND_VALUE];
}

export function useScoringDataForGroup({ groupId }) {
    const [scoringData, , status] = aggregate({
        roundOrder: useFirebase(`gameData/roundOrder`, []),
        scoredAnswersForGroup: useFirebase(`scoredAnswers/${groupId}/rounds`, {}),
        rounds: useFirebase(`gameData/rounds`, {}),
    });
    if (isPending(status)) {
        return [{}, READ_ONLY, PENDING_VALUE];
    }
    return [scoringData, READ_ONLY, FOUND_VALUE];
}

export function useOrderedScoredGroups({
    sortBy,
    showDisabled = false,
    includeDemoRounds = false,
    showDemoRoundScoreIfCurrent = true,
} = {}) {
    const [scoringData, , status] = aggregate({
        groups: useFirebase(`groups`, {}),
        roundOrder: useFirebase(`gameData/roundOrder`, []),
        scoredAnswersForGroup: useFirebase(`scoredAnswers`, {}),
        rounds: useFirebase(`gameData/rounds`, {}),
        currentRound: useFirebase('gameData/currentRound', null),
        calcAbsoluteTimePoints: useFirebase('gameData/settings/calcAbsoluteTimePoints', true),
        boardType: useScoreboardType(),
    });
    if (isPending(status)) {
        return [[], READ_ONLY, PENDING_VALUE];
    }
    if (!includeDemoRounds) {
        scoringData.roundOrder = filterDemoRounds(
            scoringData.roundOrder,
            scoringData.rounds,
            scoringData.currentRound,
            { showDemoRoundScoreIfCurrent }
        );
    }
    const scoredGroups = Object.entries(scoringData.groups)
        .map(([groupId, groupData]) => {
            if (!showDisabled && groupData?.disabled) {
                return false;
            }
            const scoringDataForGroup = {
                roundOrder: scoringData.roundOrder,
                scoredAnswersForGroup: scoringData.scoredAnswersForGroup?.[groupId]?.rounds || {},
                rounds: scoringData.rounds,
            };
            const groupScore = getGameScoreForGroup(scoringDataForGroup, {
                calcAbsoluteTimePoints: scoringData.calcAbsoluteTimePoints,
            });
            return {
                groupId,
                groupName: getGroupReadOnlyDisplayName(groupData),
                groupScore: groupScore.score ? 1 * groupScore.score : groupScore.score,
                accumulatedGroupTime: groupScore.accumulatedGroupTime,
                roundPoints: groupScore.roundPoints || [],
            };
        })
        .filter(Boolean);
    const sortKey = sortBy || scoringData.boardType === ScoreBoardType.TIME ? 'accumulatedGroupTime' : 'groupScore';
    const sortArr = sortKey === 'groupScore' ? [sortKey, 'roundPoints'] : [sortKey];
    let orderedScoredGroups = _.sortBy(scoredGroups, sortArr);
    if (sortKey === 'groupScore') {
        orderedScoredGroups = _.reverse(orderedScoredGroups);
    }
    return [orderedScoredGroups, READ_ONLY, FOUND_VALUE];
}

export function usePlayers(initialValue, options = {}) {
    return useFirebase('players', initialValue, { firebaseRootRefProvider: firebaseAbsoluteRoot, ...options });
}

export function useCurrentParticipants({ groupId }) {
    const [activeParticipants, , status] = useFirebase('activeParticipants', {});
    const currentParticipants = useMemo(() => getGroupCurrentParticipants(activeParticipants, groupId), [
        activeParticipants,
        groupId,
    ]);
    return [currentParticipants, READ_ONLY, status];
}

export function useUserData({ userId, ...options }) {
    return useFirebase(`players/${userId}`, {}, { firebaseRootRefProvider: firebaseAbsoluteRoot, ...options });
}

export function useValidGames() {
    const [gamesData, setGamesData] = useState({});
    const [status, setStatus] = useState(PENDING_VALUE);
    const updateGames = useCallback(async () => {
        const allGames = await getValue('games', {}, { root: true });
        setGamesData(allGames);
        setStatus(FOUND_VALUE);
    }, []);
    useEffect(() => {
        let unsubscribes = [];
        updateGames().then(() => {
            unsubscribes = [
                firebaseAbsoluteRoot('games').on('child_removed', updateGames),
                firebaseAbsoluteRoot('games').on('child_moved', updateGames),
            ];
        });
        return () => {
            unsubscribes.forEach((fn) => fn && fn());
        };
    }, [updateGames]);
    const validGames = Object.entries(gamesData)
        .filter(([_, game]) => {
            return game?.gameData?.official && !game?.gameData?.deleted;
        })
        .sort(([_, gameA], [__, gameB]) => {
            return (gameA?.gameData?.date || 0) - (gameB?.gameData?.date || 0);
        });
    return [validGames, updateGames, status];
}

export function useHasLetterInHashCode({ letter, roundId, index }) {
    const [hashCodeByLetter, , hashCodeByLetterStatus] = useFirebase(`gameData/correctCodes/${roundId}/byLetter`, '');
    if (!isFound(hashCodeByLetterStatus)) {
        return [false, READ_ONLY, hashCodeByLetterStatus];
    }
    const letterStatus = getLetterStatus(hashCodeByLetter, letter, roundId, index);
    return [letterStatus, READ_ONLY, hashCodeByLetterStatus];
}

export function useTimer({ roundId }) {
    return aggregate({
        remainingTime: useFirebase(`gameData/timer`, 0),
        totalTime: useFirebase(`gameData/rounds/${roundId}/totalTime`, 0),
    });
}

export function useScoreboardType() {
    return useFirebase('gameData/settings/scoreBoardType', ScoreBoardType.TIME);
}

export function useAnswerMarkers() {
    const base = 'https://remote-quiz-1.s3.us-east-1.amazonaws.com/images';
    const defaults = {
        [MarkerType.ONE_IDLE]: `${base}/one_empty.png`,
        [MarkerType.ONE_ACTIVE]: `${base}/one_full.png`,
        [MarkerType.ZERO_IDLE]: `${base}/zero_empty.png`,
        [MarkerType.ZERO_ACTIVE]: `${base}/zero_full.png`,
        [MarkerType.HALF_IDLE]: `${base}/half_empty.png`,
        [MarkerType.HALF_ACTIVE]: `${base}/half_full.png`,
    };
    const [markers, markersChangers, markersStatus] = useFirebase('gameData/settings/answerMarkers', null, {
        defaultValue: {},
    });
    return [!markers ? markers : { ...defaults, ...markers }, markersChangers, markersStatus];
}
