import React from 'react';
import cx from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import { green, red, blue, grey } from '@material-ui/core/colors';
import { useCallback } from 'react';

export const AddButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[700]),
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);

export const DisabledButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[700]),
        backgroundColor: grey[500],
        '&:hover': {
            backgroundColor: grey[700],
        },
    },
}))(Button);

export const RedButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(red[700]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
}))(Button);

export const BlueButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(blue[700]),
        backgroundColor: blue[500],
        '&:hover': {
            backgroundColor: blue[700],
        },
    },
}))(Button);

export function ToggleButton({
    text,
    onText = text,
    offText = text,
    isOn,
    onToggle,
    className,
    disabled,
    onButton,
    offButton,
    buttonComponentSelector,
    value,
    ...rest
}) {
    let Component;
    if (buttonComponentSelector) {
        Component = buttonComponentSelector(value, isOn, { disabled });
    }
    if (!Component) {
        Component = disabled ? DisabledButton : isOn ? onButton || RedButton : offButton || BlueButton;
    }
    const buttonText = isOn ? offText : onText;
    const clickHandler = useCallback(() => {
        if (!disabled) {
            onToggle(!isOn);
        }
    }, [onToggle, isOn, disabled]);
    return (
        <Component className={cx(className)} onClick={clickHandler} disabled={disabled} {...rest}>
            {buttonText}
        </Component>
    );
}
