import { Language } from '../consts';
const { HEB, ENG } = Language;

export default {
    [HEB]: {
        'chat.player.group.placeholder': 'הקלד הודעה לקבוצה...',
        'chat.player.group.send': 'שלח',
        'chat.player.general.placeholder': `הקלד הודעה בצ'אט הכללי...`,
        'chat.player.general.sendAll': 'שלח לכולם',
        'chat.quizmaster.general.placeholder': `הקלד הודעה בצ'אט הכללי...`,
        'chat.quizmaster.general.send': 'שלח',
        'chat.quizmaster.breaking.placeholder': `הקלד הודעה מתפרצת...`,
        'chat.quizmaster.breaking.sendAll': 'שלח לכולם',
        'chat.groupChat': `צ'אט קבוצתי`,
        'chat.allChat': `צ'אט כללי`,
        'chat.delete': 'מחק',
        'agora.initCam': 'מאתחל מצלמה...',
        'agora.initCamComplete': 'אתחול מצלמה הושלם בהצלחה',
        'agora.initMic': 'מצלמה לא נמצאה, מאתחל מיקרופון...',
        'agora.initMicComplete': 'אתחול מיקרופון הושלם בהצלחה',
        'agora.noInit': 'לא התקבל אישור למצלמה או מיקרופון',
        'game.welcome': 'ברוכים הבאים! מיד נתחיל במשחק',
        'game.thanks': 'תודה ששיחקתם איתנו',
        'game.thanksWin': 'אתם הזוכים! תודה ששיחקתם איתנו!',
        'game.pleaseWait': 'אנא המתינו להתחלת הסיבוב הבא',
        'game.waitFinishRound': 'אנא המתינו לסיום הסיבוב',
        'game.successSubmit': 'הגשתם בהצלחה בזמן של {time}!',
        'code.cancelSubmitTitle': 'ביטול הגשה',
        'code.cancelSubmitContent': 'האם אתם בטוחים? זה יאפס את תוצאתכם',
        'code.cancelSubmitTitle2': 'ביטול הגשה - וידוא',
        'code.cancelSubmitContent2': 'האם אתם בטוחים שאתם בטוחים? זה יאפס את תוצאתכם!',
        'code.submit': 'הגש',
        'code.cancelSubmit': 'בטל הגשה',
        'code.wrongCode': 'הקוד שהוגש אינו נכון, נסו שנית',
        'answers.correctAnswer': 'התשובה הנכונה',
        'answers.ourAnswer': 'התשובה שלנו',
        'answers.ourScore': 'הניקוד שקיבלנו',
        'answers.theirAnswer': 'התשובה של {name}',
        'login.hello': 'שלום! ספרו לנו מי אתם',
        'scores.locked': 'תוצאות נעולות',
        'audio.plays': 'השמעות',
        'profile.word': 'פרופיל',
        'profile.title': 'ערוך פרופיל',
        'profile.save': 'שמור',
        'profile.cancel': 'בטל',
        'profile.disconnect': 'התנתקות',
        'game.inactive': 'המשחק אינו פעיל עדיין... בקרוב',
        'game.noGroup': 'אופס... נראה שהקבוצה הזאת לא פעילה',
        'game.otherGroup': 'אופס... נראה שאתם מחוברים כבר לקבוצה אחרת',
        'game.switch': 'עבור לקבוצה {group}',
        'nogame.link': 'שלום! אנא הזינו למעלה את הלינק שקיבלתם',
        'expl.group': 'ההתכתבות גלויה לחברי הקבוצה בלבד',
        'expl.general': 'ההתכתבות גלויה לכל השחקנים ולקוויזמאסטר',
        'info.mute': 'בזמן שידור הקוויזמאסטר, השחקנים מושתקים',
        'mediaSettings.title': 'הגדרות מדיה',
        'question.number.text': 'שאלה מספר',
        'answer.number.text': 'תשובה מספר',
        'general.notes': 'הערות',
        'submit.submitAnswers': 'הגש תשובות',
        'submit.areYouSure': 'האם אתם בטוחים?',
        'general.error': 'אופס, נראה שישנה שגיאה. אנא נסו לרפרש',
        'mediaSettings.camera': 'מצלמה',
        'mediaSettings.camera.success': 'מצלמה זוהתה בהצלחה',
        'mediaSettings.camera.notAllowed': 'לא נתקבל אישור למצלמה',
        'mediaSettings.camera.notFound': 'מצלמה לא נמצאה',
        'mediaSettings.camera.notReadable': 'לא ניתן לגשת למצלמה, אנא בדקו אם יש תוכנה אחרת המשתמשת בה',
        'mediaSettings.camera.error': 'לא ניתן לגשת למצלמה',
        'mediaSettings.camera.pending': 'ממתין לקבלת אישור מצלמה',
        'mediaSettings.mic': 'מיקרופון',
        'mediaSettings.mic.success': 'מיקרופון זוהה בהצלחה',
        'mediaSettings.mic.notAllowed': 'לא נתקבל אישור למיקרופון',
        'mediaSettings.mic.notFound': 'מיקרופון לא נמצא',
        'mediaSettings.mic.notReadable': 'לא ניתן לגשת למיקרופון, אנא בדקו אם יש תוכנה אחרת המשתמשת בו',
        'mediaSettings.mic.error': 'לא ניתן לגשת למיקרופון',
        'mediaSettings.mic.pending': 'ממתין לקבלת אישור מיקרופון',
    },
    [ENG]: {
        'chat.player.group.placeholder': 'Type a message for the group...',
        'chat.player.group.send': 'Send',
        'chat.player.general.placeholder': 'Type a message for everyone...',
        'chat.player.general.sendAll': 'Send All',
        'chat.quizmaster.general.placeholder': 'Type a message in general chat...',
        'chat.quizmaster.general.send': 'Send',
        'chat.quizmaster.breaking.placeholder': 'Type a breaking message for all participants...',
        'chat.quizmaster.breaking.sendAll': 'Send All',
        'chat.groupChat': 'Group Chat',
        'chat.allChat': 'General Chat',
        'chat.delete': 'Delete',
        'agora.initCam': 'Looking for camera...',
        'agora.initCamComplete': 'Camera found',
        'agora.initMic': 'Camera unavailable, init mic...',
        'agora.initMicComplete': 'Mic found',
        'agora.noInit': 'Camera/Mic are unavailable',
        'game.welcome': 'Welcome! The game will start shortly',
        'game.thanks': 'Thanks for playing!',
        'game.thanksWin': 'You are the winners! Thanks for playing!',
        'game.pleaseWait': 'Please wait for the next round',
        'game.waitFinishRound': 'Please wait for this round to end',
        'game.successSubmit': 'You have successfully submitted in {time}!',
        'code.cancelSubmitTitle': 'Cancel submission',
        'code.cancelSubmitContent': 'Are you sure? This will clear your result',
        'code.cancelSubmitTitle2': 'Cancel submission - confirm',
        'code.cancelSubmitContent2': 'Are you definitely sure? This will clear your result!',
        'code.submit': 'Submit',
        'code.cancelSubmit': 'Cancel submit',
        'code.wrongCode': 'The code is incorrect, please try again',
        'answers.correctAnswer': 'Correct Answer',
        'answers.ourAnswer': 'Our Answer',
        'answers.ourScore': 'Our Score',
        'answers.theirAnswer': `{name}'s Answer`,
        'login.hello': 'Hello! Tell us who you are',
        'scores.locked': 'Locked Scores',
        'audio.plays': 'plays',
        'profile.word': 'Profile',
        'profile.title': 'Edit Profile',
        'profile.save': 'Save',
        'profile.cancel': 'Cancel',
        'profile.disconnect': 'Disconnect',
        'game.inactive': 'This game is not active yet',
        'game.noGroup': 'Looks like this group is not active',
        'game.otherGroup': `Oops... Looks like you're already in another group`,
        'game.switch': 'Switch to {group}',
        'nogame.link': 'Hello! Please insert above the link you received',
        'expl.group': 'This chat is visibile only to your group',
        'expl.general': 'This chat is visible to all players and quizmaster',
        'info.mute': 'When the quizmaster broadcasts, the players are muted',
        'mediaSettings.title': 'Media Settings',
        'question.number.text': 'Question',
        'answer.number.text': 'Answer',
        'general.notes': 'Notes',
        'submit.submitAnswers': 'Submit answers',
        'submit.areYouSure': 'Are you sure?',
        'general.error': 'Oops, something went wrong. Please refresh.',
        'mediaSettings.camera': 'Camera',
        'mediaSettings.camera.success': 'Camera found',
        'mediaSettings.camera.notAllowed': 'Camera access is blocked',
        'mediaSettings.camera.notFound': 'Camera not found',
        'mediaSettings.camera.notReadable': 'Camera inaccessible, please check for other programs that might use it',
        'mediaSettings.camera.error': 'Camera inaccessible',
        'mediaSettings.camera.pending': 'Looking for camera...',
        'mediaSettings.mic': 'Microphone',
        'mediaSettings.mic.success': 'Microphone found',
        'mediaSettings.mic.notAllowed': 'Microphone access is blocked',
        'mediaSettings.mic.notFound': 'Microphone not found',
        'mediaSettings.mic.notReadable': 'Microphone inaccessible, please check for other programs that might use it',
        'mediaSettings.mic.error': 'Microphone inaccessible',
        'mediaSettings.mic.pending': 'Looking for microphone...',
    },
};
