import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import styles from './FilePicker.module.scss';
import { useState, useRef, useCallback } from 'react';
import { uploadFile } from '../firebase/firebase-storage';
import { BlueButton } from '../components/AddButton';
import { MediaType } from '../common/consts';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';

class UploadStore {
    @observable bytes;
    @observable total;
}

export default function FilePicker({ mediaType, onUpload, className, uploadFolder }) {
    const [uploading, setUploading] = useState(false);
    const inputRef = useRef(null);
    const uploadStore = useRef(null);

    useEffect(() => {
        uploadStore.current = new UploadStore();
    }, []);

    const allowedFiles = useMemo(() => {
        const fileTypes = {
            [MediaType.MUSIC]: 'audio/*',
            [MediaType.IMAGE]: 'image/*',
        };
        return fileTypes[mediaType];
    }, [mediaType]);

    const updateUploadStore = useCallback((transferred, total) => {
        const store = uploadStore.current;
        if (store) {
            store.bytes = transferred;
            store.total = total;
        }
    }, []);

    const onFileUpload = useCallback(
        async (event) => {
            const file = event.target.files[0];
            if (file) {
                setUploading(true);
                const url = await uploadFile(file, { folder: uploadFolder, onProgress: updateUploadStore });
                setUploading(false);
                onUpload(url);
            }
        },
        [uploadFolder, updateUploadStore, onUpload]
    );

    const onClick = useCallback(() => {
        inputRef.current.click();
    }, [inputRef]);

    return (
        <div className={cx(styles.main, className)}>
            <input className={styles.input} ref={inputRef} onChange={onFileUpload} type='file' accept={allowedFiles} />
            {uploading ? (
                <Progress uploadStore={uploadStore.current} />
            ) : (
                <BlueButton onClick={onClick} variant='contained' color='primary' size='medium' disabled={uploading}>
                    העלאת קובץ
                </BlueButton>
            )}
        </div>
    );
}

const Progress = observer(({ uploadStore }) => {
    const text = `מעלה קובץ... ${uploadStore ? Math.round(100 * (uploadStore.bytes / uploadStore.total)) : ''}%`;
    return <div>{text}</div>;
});
