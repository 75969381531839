import enums from 'enumish';

export const RoundMode = enums(['OPEN', 'LOCKED', 'CHECK', 'ENDED']);
export const RoundModeType = enums(['PLAY', 'CHECK']);
export const MediaType = enums(['IMAGE', 'VIDEO', 'MUSIC', 'MAP']);
export const GameMode = enums(['INACTIVE', 'BEFORE', 'ACTIVE', 'END']);

export const ChatTypes = enums(['QUIZMASTER', 'GROUP']);

export const Subchat = enums({ GENERAL: '_general', QUIZMASTER: '_quizmaster' });
export const ScoreBoardType = enums(['TIME', 'SCORE']);

export const Language = enums(['HEB', 'ENG']);
export const LangArea = enums(['GAME', 'ADMIN']);

export const Strings = {
    quizmaster: 'Quizmaster',
    editMode: 'מצב עריכה',
};

export const LetterStatus = enums(['OK', 'MISPLACED', 'MISSING']);

export const VideoProfile = { LOW: '120p_3', MEDIUM: '180p_3', HIGH: '240p_3' };

export const FirebaseValue = enums(['PENDING', 'MISSING', 'FOUND']);

export const ChatScope = enums(['GROUP', 'GENERAL']);

export const GameId = enums(['NO_SPECIFIC_GAME']);

export const MarkerType = enums(['ONE_IDLE', 'ONE_ACTIVE', 'HALF_IDLE', 'HALF_ACTIVE', 'ZERO_IDLE', 'ZERO_ACTIVE']);
