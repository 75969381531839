import React from 'react';
import cx from 'classnames';
import { openDialog } from '../../common/dialogOpener';
import { useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { RedButton, AddButton } from '../AddButton';

export function openConfirmation({ ...props } = {}) {
    return new Promise((resolve) => {
        openDialog(({ renderIntoPopup, closePopup }) => {
            const confirm = async function () {
                resolve();
                closePopup();
            };
            renderIntoPopup(<Confirmation open onClose={closePopup} onYes={confirm} onNo={closePopup} {...props} />);
        });
    });
}

export function Confirmation({
    children,
    open = true,
    onClose,
    content,
    onYes,
    title,
    onNo,
    danger,
    yesText = 'כן',
    noText = 'לא',
    className,
    ...props
}) {
    const handleClose = useCallback(() => onClose(), [onClose]);
    const ConfirmButton = danger ? RedButton : AddButton;
    const renderChildren = content || children;
    return (
        <Dialog
            maxWidth='sm'
            fullWidth
            open={open}
            onClose={handleClose}
            className={cx('fullPageDialog', className)}
            {...props}
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                {React.isValidElement(renderChildren) ? (
                    renderChildren
                ) : (
                    <DialogContentText>{renderChildren}</DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onNo}>{noText}</Button>
                <ConfirmButton onClick={onYes}>{yesText}</ConfirmButton>
            </DialogActions>
        </Dialog>
    );
}
