import { useEffect, useState } from 'react';
import { PENDING_VALUE, FOUND_VALUE } from '../firebase/firebase-consts';
import { LetterStatus, MarkerType, RoundMode, VideoProfile } from './consts';
import { getGameIdFromRoute } from '../routes';
import { encode } from 'firebase-encode';
import gameState from '../stores/State';
import { changeValue, getServerTimeStamp, getValue } from '../firebase/firebase-storage';

export function toTimer(number) {
    const num = Number.isNaN(Number(number)) ? 0 : Number(number);
    const minutes = Math.floor(Number(num) / 60);
    const seconds = Math.floor(Number(num) % 60);
    return [pad(minutes, 2), pad(seconds, 2)];
}

function pad(num, size) {
    var s = '000000000' + num;
    return s.substr(s.length - size);
}

export function generateId(size = 3, prefix) {
    return `${prefix ? prefix.toLowerCase() : ''}${Math.random()
        .toString(32)
        .slice(2, 2 + size)}`;
}

export function isInputActive() {
    return (
        document.activeElement.tagName.toLowerCase() === 'input' ||
        document.activeElement.tagName.toLowerCase() === 'textarea'
    );
}

export function useKeyboard(callbackStableRef, keys) {
    useEffect(() => {
        function onEvent(event) {
            if (isInputActive()) {
                return;
            }
            if (!keys || keys.some((key) => event.key === key)) {
                callbackStableRef(event);
            }
        }
        window.addEventListener('keydown', onEvent);
        return () => window.removeEventListener('keydown', onEvent);
    }, [callbackStableRef, keys]);
}

export function usePromise(promiseStableRef, initialValue) {
    const [resultObj, setResultObj] = useState({ status: PENDING_VALUE, value: initialValue });

    useEffect(() => {
        const p = typeof promiseStableRef === 'function' ? promiseStableRef() : promiseStableRef;
        p.then((result) => {
            setResultObj({ status: FOUND_VALUE, value: result });
        });
    }, [promiseStableRef]);

    return [resultObj.value, resultObj.status];
}

export function generateCode({ answers, questionsOrder = [] }) {
    const getAnswer = (answers, questionId) => answers[questionId]?.value || answers[questionId] || '';
    return questionsOrder.map((questionId) => (getAnswer(answers, questionId) || '')[0]?.toLowerCase() || ' ').join('');
}

export function getHashCode(str) {
    let hash = 0;
    if (!str || str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

export function getRandomPastelColor() {
    return 'hsl(' + 360 * Math.random() + ',' + (25 + 70 * Math.random()) + '%,' + (85 + 10 * Math.random()) + '%)';
}

export function log(...args) {
    return console.log(...args);
}

export function getGameId(location) {
    try {
        return getGameIdFromRoute(location.pathname);
    } catch (err) {
        return null;
    }
}

export function getQuizmasterbcChannelId(gameId) {
    return `quizmaster_broadcast_${gameId}`;
}

export function calculateTimeScore(
    actualTime,
    totalTime,
    { calcAbsoluteTimePoints = true, calcAbsoluteTimeOutOf = 15 * 60 } = {}
) {
    if (!actualTime || !totalTime) {
        return [0, actualTime || 0, totalTime || 0];
    }
    try {
        const totalTimeFloat = parseFloat(totalTime);
        const actualTimeFloat = Math.min(parseFloat(actualTime), totalTimeFloat);
        if (!actualTimeFloat || !totalTimeFloat) {
            return [0, actualTimeFloat || 0, totalTimeFloat || 0];
        }
        let points = 0;
        if (totalTimeFloat !== actualTimeFloat) {
            const COEFF = 11;
            const totalTimeForCalculation = parseFloat(calcAbsoluteTimePoints ? calcAbsoluteTimeOutOf : totalTimeFloat);
            const frac = (1 * totalTimeForCalculation - 1 * actualTimeFloat) / totalTimeForCalculation;
            points = Math.max(COEFF * frac, 0);
        }
        return [points.toFixed(2), actualTimeFloat, totalTimeFloat];
    } catch (err) {
        log(`Error calculating time points ${actualTime} / ${totalTime}`);
        return [0, 0, 0];
    }
}

export function canPlayersSeeAnswers(roundMode) {
    return roundMode === RoundMode.CHECK || roundMode === RoundMode.LOCKED;
}

export function getGroupReadOnlyDisplayName(groupData = {}) {
    return (groupData?.displayName?.value || '') + (groupData?.disabled ? '*' : '');
}

const YOUTUBE_REGEX = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
export function parseYoutubeLink(url) {
    let result = '';
    try {
        const match = url.match(YOUTUBE_REGEX);
        const videoId = match[1];

        result = `https://www.youtube.com/embed/${videoId}`;
    } catch (err) {
        //
    }
    return result;
}

export function getOrigin() {
    try {
        const url = new URL(window.location.href);
        return url.origin;
    } catch (err) {
        //
    }
    try {
        return window.location.protocol + '//' + window.location.hostname;
    } catch (err) {
        //
    }
    return '';
}

export function getFileName(src) {
    let name = src;
    try {
        const url = new URL(src);
        let path = url.pathname;
        try {
            path = decodeURIComponent(path);
        } catch (err) {
            //
        }
        const matcher = path.split('/');
        name = matcher.length ? matcher[matcher.length - 1] : src;
    } catch (err) {
        //
    }
    return encode(name);
}

export function getGroupCurrentParticipants(activeParticipants, groupId) {
    return Object.entries(activeParticipants)
        .map(([userId, groupId]) => {
            return { userId, groupId };
        })
        .filter((item) => item.groupId === groupId);
}

export function getHashCodePerLetter(str, key = '') {
    const sorted = [...str];
    return sorted.map((char) => getHashCode(key + char)).join('_');
}

export function getLetterStatus(hashCode, letter, key = '', index) {
    const letterCode = getHashCode(key + letter);
    const found = hashCode.indexOf(letterCode) > -1;
    if (!found) {
        return LetterStatus.MISSING;
    }
    // eslint-disable-next-line eqeqeq
    const inplace = hashCode.split('_')[index] == letterCode;
    if (inplace) {
        return LetterStatus.OK;
    } else {
        return LetterStatus.MISPLACED;
    }
}

export function getLocalStorageKey(key) {
    return `PQ_${gameState.gameId || ''}_${key}`;
}

export function filterDemoRounds(
    allRoundsOrder = [],
    allRoundsData = {},
    currentRoundId = null,
    { showDemoRoundScoreIfCurrent } = {}
) {
    if (showDemoRoundScoreIfCurrent && currentRoundId) {
        const currentDemoRound = allRoundsOrder.find((roundId) => {
            return allRoundsData?.[roundId]?.demoRound && roundId === currentRoundId;
        });
        if (currentDemoRound) {
            return [currentDemoRound];
        }
    }
    return allRoundsOrder.filter((roundId) => !allRoundsData?.[roundId]?.demoRound);
}

export async function getTimeDifferenceWithServer({ userId, threshold = 3000 }) {
    await changeValue(`timerSync/${userId}`, { serverTime: getServerTimeStamp(), userTime: Date.now() });
    const values = await getValue(`timerSync/${userId}`, {});
    await changeValue(`timerSync/${userId}`, null);
    const diff = 1 * values.userTime - 1 * values.serverTime;
    if (Number.isNaN(diff) || diff <= threshold) {
        return 0;
    } else {
        return diff;
    }
}

export function getVideoProfile() {
    return VideoProfile.MEDIUM;
}

export function getAdminVideoProfile() {
    return VideoProfile.MEDIUM;
}
export function splitFilter(array, pred) {
    const [yes, no] = [[], []];
    array.forEach((el) => {
        if (pred(el)) {
            yes.push(el);
        } else {
            no.push(el);
        }
    });
    return [yes, no];
}

export function sliceValidGames(validGames = [], { minNum = 10 } = {}) {
    const [currentMonth, currentYear] = [new Date().getMonth(), new Date().getFullYear()];
    let [currentMonthGames, otherGames] = splitFilter(validGames, ([_, game]) => {
        if (game?.date) {
            const gameDate = new Date(game?.date);
            return gameDate.getMonth() === currentMonth && gameDate.getFullYear() === currentYear;
        }
        return false;
    });
    if (currentMonthGames.length < minNum) {
        const cutoffNum = Math.min(minNum - currentMonthGames.length, otherGames.length);
        const cutoffIndex = otherGames.length - cutoffNum;
        const rest = otherGames.slice(cutoffIndex, otherGames.length);
        currentMonthGames = rest.concat(currentMonthGames);
        otherGames = otherGames.slice(0, cutoffIndex);
    }
    return [
        currentMonthGames.sort(([_, gameA], [__, gameB]) => {
            return (gameA?.date || 0) - (gameB?.date || 0);
        }),
        otherGames,
    ];
}

export function getExtension(text = '') {
    return (text.split('?')[0] || '').split('.').pop();
}

export function isPhoto(content) {
    return content?.startsWith('data:image') || ['jpg', 'jpeg', 'png', 'gif'].includes(getExtension(content));
}

export function getTotalRegisteredParticipants(groups = {}) {
    return Object.entries(groups)
        .map(([groupId, groupData]) => {
            if (groupData?.disabled) {
                return 0;
            }
            return 1 * (groupData?.allowedParticipants || 0);
        })
        .reduce((acc, cur) => acc + cur, 0);
}

export function phantomLast(groupsObj = {}) {
    return Object.fromEntries(
        Object.entries(groupsObj).sort(([, dataA], [, dataB]) => {
            return dataA?.disabled ? 1 : dataB?.disabled ? -1 : 0;
        })
    );
}

const markerTypes = { 0: 'ZERO', 0.5: 'HALF', 1: 'ONE' };
export function getMarkerType(scoreType, currentScore) {
    const name = `${markerTypes[scoreType]}_${scoreType === currentScore ? 'ACTIVE' : 'IDLE'}`;
    return MarkerType[name];
}
