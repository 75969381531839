import { observable, action, computed, autorun } from 'mobx';
import { Language, LangArea } from '../common/consts';
import { onValue } from '../firebase/firebase-storage';

function getBrowserLanguage() {
    const lang = navigator.language || navigator.userLanguage;
    if (!lang || lang === 'he') {
        return Language.HEB;
    } else {
        return Language.ENG;
    }
}

class LanguageState {
    @observable langs = new Map();
    @observable langsPending = new Map();
    @observable gameId;

    constructor() {
        this.langs.set(LangArea.GAME, getBrowserLanguage());
        this.langs.set(LangArea.ADMIN, getBrowserLanguage());

        this.langsPending.set(LangArea.GAME, true);
        this.langsPending.set(LangArea.ADMIN, true);
        autorun(() => {
            if (this.gameId) {
                onValue('gameData/language', (language) => {
                    this.setGameLanguage(language || Language.HEB);
                    this.setAdminLanguage(language || Language.HEB);
                    this.langsPending.set(LangArea.GAME, false);
                    this.langsPending.set(LangArea.ADMIN, false);
                });
            }
        });
    }

    @action setGameLanguage(gameLang) {
        this.langs.set(LangArea.GAME, gameLang);
    }

    @action setAdminLanguage(adminLang) {
        this.langs.set(LangArea.ADMIN, adminLang);
    }

    @action setGameId(gameId) {
        this.gameId = gameId;
    }

    getLang(area) {
        return this.langs.get(area) || Language.HEB;
    }

    @computed get isGameRTL() {
        return this.langs.get(LangArea.GAME) === Language.HEB;
    }

    @computed get isAdminRTL() {
        return this.langs.get(LangArea.ADMIN) === Language.HEB;
    }

    @computed get adminLangClassName() {
        return this.isAdminRTL ? 'lang-rtl' : 'lang-ltr';
    }

    @computed get gameLangClassName() {
        return this.isGameRTL ? 'lang-rtl' : 'lang-ltr';
    }

    @computed get isGamePending() {
        return this.langsPending.get(LangArea.GAME);
    }

    @computed get isAdminPending() {
        return this.langsPending.get(LangArea.ADMIN);
    }
}

window.languageState = window.languageState || new LanguageState();

export default window.languageState;
