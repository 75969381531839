import { VmShape } from '..';
const WATCHDOG_PERIOD = 1000;
export class MeterRenderer {
    constructor(ctx, { height, width, shape = VmShape.VM_STEPPED }) {
        this.canvasCtx = ctx;
        this.height = height;
        this.width = width;
        this.shape = shape;
    }
    init() {
        this.stop();
    }
    startWatchdog() {
        this.watchdog = +new Date();
        this.watchdogTimer = window.setTimeout(() => {
            this.checkWatchdog();
        }, WATCHDOG_PERIOD / 2);
    }
    checkWatchdog() {
        const now = +new Date();
        if (now - this.watchdog > WATCHDOG_PERIOD) {
            this.watchdogExpired = true;
            this.draw(0);
        } else {
            this.watchdogExpired = false;
        }
        this.startWatchdog();
    }
    start() {
        this.prevVolume = 0;
        this.watchdogExpired = false;
        this.startWatchdog();
    }
    stop() {
        this.prevVolume = 0;
        window.clearTimeout(this.watchdogTimer);
        this.watchdogExpired = false;
        this.draw(0);
    }
    clear() {
        const { canvasCtx, width, height } = this;
        canvasCtx.clearRect(0, 0, width, height);
    }
    draw(volume) {
        this.watchdog = +new Date();
    }
}
