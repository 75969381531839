import ReactDOM from 'react-dom';

export function openDialog(initializer) {
    if (!initializer) {
        return;
    }
    const container = document.querySelector('.fullPage') || document.createElement('div');
    container.classList.add('fullPage');
    document.body.appendChild(container);
    try {
        ReactDOM.unmountComponentAtNode(container);
    } catch (err) {
        //
    }

    const renderIntoPopup = (reactElement) => ReactDOM.render(reactElement, container);
    const closePopup = () => {
        ReactDOM.unmountComponentAtNode(container);
        container.parentElement.removeChild(container);
    };

    initializer({ renderIntoPopup, closePopup });
}
