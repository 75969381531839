import React, { useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DotBadgeUserIcon from '../components/DotBadgeUserIcon';
import gameState from '../stores/State';
import { signOut } from '../services/authService';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VideocamIcon from '@material-ui/icons/Videocam';
import { openConfirmation } from '../components/dialogs/Confirmation';
import UserProfile from './UserProfile';
import { persistPlayer } from '../services/playerService';
import LanguageWrapper, { getString, isRTL } from '../components/LanguageWrapper';
import { LangArea } from '../common/consts';
import CameraSettingsComponent from '../settings/CameraSettingsComponent';
import styles from './UserMenu.module.scss';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
    root: {
        transform: 'translateZ(0px)',
        flexGrow: 1,
    },
    speedDial: {
        position: 'fixed',
        top: '-12px',
        right: '-3px',
        transform: 'scale(0.7)',
    },
}));

const UserMenu = observer(function UserMenu() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const displayNameInEdit = useRef(gameState.displayName);
    const photoInEdit = useRef(gameState.userPhoto);

    const onDeviceChange = useCallback((type, deviceId) => {
        // eslint-disable-next-line no-unused-expressions
        window?.agora?.switchDevice(type, deviceId);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const str = useCallback((key) => {
        return getString(key, LangArea.GAME);
    }, []);

    const modalClassName = isRTL(LangArea.GAME) ? styles.rtlLang : styles.ltrLang;

    const actions = [
        {
            icon: <AccountCircleIcon />,
            name: str('profile.word'),
            onAction: async () => {
                await openConfirmation({
                    title: str('profile.title'),
                    yesText: str('profile.save'),
                    noText: str('profile.cancel'),
                    content: (
                        <UserProfile
                            userId={gameState.userId}
                            initialDisplayName={gameState.displayName}
                            initialImageUrl={gameState.userPhoto}
                            onPhotoChange={(editedPhoto) => (photoInEdit.current = editedPhoto)}
                            onNameChange={(editedDisplayName) => (displayNameInEdit.current = editedDisplayName)}
                        />
                    ),
                });

                persistPlayer(gameState.userId, {
                    displayName: displayNameInEdit.current || gameState.displayName,
                    photo: photoInEdit.current || gameState.userPhoto,
                });
            },
        },
        {
            icon: <VideocamIcon />,
            name: str('mediaSettings.title'),
            onAction: async () => {
                await openConfirmation({
                    title: str('mediaSettings.title'),
                    yesText: str('profile.save'),
                    noText: str('profile.cancel'),
                    maxWidth: false,
                    fullWidth: false,
                    className: modalClassName,
                    content: (
                        <LanguageWrapper langArea={LangArea.BROWSER}>
                            <CameraSettingsComponent darkText onDeviceChange={onDeviceChange} />
                        </LanguageWrapper>
                    ),
                });
            },
        },
        { icon: <VpnKeyIcon />, name: str('profile.disconnect'), onAction: () => signOut() },
    ];
    return (
        <SpeedDial
            ariaLabel='UserMenu'
            className={classes.speedDial}
            icon={<DotBadgeUserIcon userId={gameState.userId} />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={'down'}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.onAction}
                />
            ))}
        </SpeedDial>
    );
});

export default UserMenu;
