import React, { useState, Suspense, lazy, useCallback } from 'react';
import './firebase/firebase-storage';
import './App.scss';
import cx from 'classnames';
import { useEffect } from 'react';
import gameState from './stores/State';
import { Switch, Route, useLocation } from 'react-router-dom';
import MemberRoute from './routing/MemberRoute';
import AdminRoute from './routing/AdminRoute';
import * as routes from './routes';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import UserMenu from './user/UserMenu';
import languageState from './stores/LanguageState';
import { GameId } from './common/consts';
import { getGameId } from './common/utils';
import NoGame from './main/NoGame';
import LanguageWrapper from './components/LanguageWrapper';
import { ErrorBoundary } from 'react-error-boundary';
import MainErrorBoundary from './routing/MainErrorBoundary';
import { isFound, isMissing, logData, useFirebase } from './firebase/firebase-storage';
const Main = lazy(() => import('./main/Main'));
const Admin = lazy(() => import('./admin/Admin'));
const Login = lazy(() => import('./login/Login'));
const Developers = lazy(() => import('./admin/developers/Developers'));
const Groups = lazy(() => import('./admin/groupsInternal/GroupsInt'));
const ScoresAdmin = lazy(() => import('./admin/scores/ScoresAdmin'));
const MainAdmin = lazy(() => import('./admin/mainAdmin/MainAdmin'));
const CameraSettingsScreen = lazy(() => import('./settings/CameraSettingsScreen'));

const theme = createMuiTheme({
    // typography: {
    //     fontFamily: 'Alef Hebrew',
    // },
});

export default function Shell() {
    const location = useLocation();
    const [gameId, setGameId] = useState('');
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const currentGameId = getGameId(location);
        if (currentGameId) {
            setGameId(currentGameId);
            if (currentGameId !== GameId.NO_SPECIFIC_GAME) {
                gameState.setGameId(currentGameId);
                languageState.setGameId(currentGameId);
                gameState.hangListeners();
            }
        }
        setReady(true);
    }, [location]);

    const onError = useCallback((errorData, info) => {
        try {
            logData({
                message: errorData?.stack,
                gameId: gameState.gameId,
                groupId: gameState.groupId,
                displayName: gameState.displayName,
            });
            console.log(errorData.stack);
        } catch (err) {
            //
        }
    }, []);

    if (!ready) {
        return null;
    }

    if (!gameId) {
        return <NoGame />;
    }

    return (
        <ErrorBoundary FallbackComponent={MainErrorBoundary} onError={onError}>
            <Suspense fallback={<LanguageWrapper className='root-container'></LanguageWrapper>}>
                <BgWrapper className='root-container' gameId={gameId}>
                    {gameState.isAuthenticated && gameId !== GameId.NO_SPECIFIC_GAME && <UserMenu />}
                    <ThemeProvider theme={theme}>
                        <Switch>
                            <AdminRoute exact path={routes.MAIN_ADMIN} component={MainAdmin}></AdminRoute>
                            <AdminRoute exact path={routes.ADMIN} component={Admin}></AdminRoute>
                            <AdminRoute exact path={routes.SCORES} component={ScoresAdmin}></AdminRoute>
                            <AdminRoute exact path={routes.DEVELOPERS} component={Developers}></AdminRoute>
                            <AdminRoute exact path={routes.GROUPS} component={Groups}></AdminRoute>
                            <Route exact path={routes.LOGIN} component={Login}></Route>
                            <MemberRoute exact path={routes.GROUP} component={Main}></MemberRoute>
                            <Route exact path={routes.SETTINGS} component={CameraSettingsScreen}></Route>
                            <Route path='*' exact component={NoGame}></Route>
                        </Switch>
                    </ThemeProvider>
                </BgWrapper>
            </Suspense>
        </ErrorBoundary>
    );
}

function BgWrapper({ className, children, gameId }) {
    if (gameId && gameId !== routes.GENERAL && gameId !== GameId.NO_SPECIFIC_GAME && gameState.isAuthenticated) {
        return <InnerBgWrapper className={cx(className, 'root-container')}>{children}</InnerBgWrapper>;
    } else {
        return <div className={cx(className, 'bg-pub root-container')}>{children}</div>;
    }
}

function InnerBgWrapper({ className, children }) {
    const [image, , imageStatus] = useFirebase(`gameData/settings/bgImage`, '');
    let style = null;
    if (isMissing(imageStatus)) {
        style = { backgroundImage: `url('https://remote-quiz-1.s3.us-east-1.amazonaws.com/images/bgg.jpg')` };
    } else if (isFound(imageStatus)) {
        style = { backgroundImage: `url(${image})` };
    } else {
        style = { backgroundImage: 'none' };
    }
    return (
        <div className={cx(className, 'bg-pub root-container')} style={style}>
            {children}
        </div>
    );
}
