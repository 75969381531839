import React from 'react';
import DotBadge from '../components/DotBadge';
import UserIcon from '../components/UserIcon';
import { makeStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        color: theme.palette.getContrastText(purple[400]),
        backgroundColor: purple[400],
    },
}));

export default function DotBadgeUserIcon({ userId }) {
    const classes = useStyles();

    return (
        <DotBadge
            overlap='circle'
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            variant='dot'
        >
            <UserIcon className={classes.icon} size='46px' userId={userId} />
        </DotBadge>
    );
}
