import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import gameState from '../stores/State';
import { autorun } from 'mobx';
import { buildPath, LOGIN, GAME_PATH_PARAM } from '../routes';
import LanguageWrapper from '../components/LanguageWrapper';
import { LangArea } from '../common/consts';

export default function AdminRoute({ children, component: Component, ...rest }) {
    const [isAuthInitialized, initAuth] = useState(false);

    useEffect(() => {
        return autorun(() => initAuth(gameState.isAuthInit));
    }, []);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isAuthInitialized ? (
                    <Pending />
                ) : gameState.isAdmin ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: buildPath(LOGIN, { [GAME_PATH_PARAM]: gameState.gameId }),
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function Pending() {
    return <LanguageWrapper langArea={LangArea.ADMIN} flex></LanguageWrapper>;
}
