import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './Modal.module.scss';

export default function Modal({ children, title, onClose, open, onSave, onDelete }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            dir='rtl'
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions className={styles.actions}>
                <div className={styles.startSide}>
                    {onDelete && (
                        <Button className={styles.delete} color='secondary' onClick={onDelete}>
                            מחק
                        </Button>
                    )}
                </div>
                <div className={styles.endSide}>
                    {onClose && (
                        <Button onClick={onClose} color='primary'>
                            סגור
                        </Button>
                    )}
                    {onSave && (
                        <Button onClick={onSave} color='primary'>
                            שמירה
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
}
