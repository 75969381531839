import React, { useMemo, createContext, useContext, useCallback } from 'react';
import cx from 'classnames';
import styles from './LanguageWrapper.module.scss';
import Flex from '../common/Flex';
import { observer } from 'mobx-react-lite';
import { LangArea } from '../common/consts.js';
import languageState from '../stores/LanguageState.js';
import strings from '../common/strings/Strings.js';
import { useRef } from 'react';
import { generateId } from '../common/utils';

export const LanguageContext = createContext({});

export default observer(function LanguageWrapper({
    className,
    children,
    flex,
    langArea = LangArea.GAME,
    center = true,
    emptyOnPending = false,
    full,
    ...rest
}) {
    const {
        gameLangClassName,
        adminLangClassName,
        isGameRTL,
        isAdminRTL,
        isGamePending,
        isAdminPending,
        langs,
    } = languageState;
    const languageClass = useMemo(() => (langArea === LangArea.GAME ? gameLangClassName : adminLangClassName), [
        adminLangClassName,
        gameLangClassName,
        langArea,
    ]);
    const uniqueId = useRef(generateId(3));
    const getStringByArea = useCallback(
        (key, replacers) => {
            return getString(key, langArea, replacers, langs.get(langArea));
        },
        [langArea, langs]
    );
    const value = useMemo(
        () => ({
            isRTL: langArea === LangArea.GAME ? isGameRTL : isAdminRTL,
            str: getStringByArea,
            strGlobal: getString,
        }),
        [langArea, isGameRTL, isAdminRTL, getStringByArea]
    );
    const Component = flex ? Flex : 'div';
    const isPending = useMemo(
        () => (langArea === LangArea.GAME && isGamePending) || (langArea === LangArea.ADMIN && isAdminPending),
        [isAdminPending, isGamePending, langArea]
    );
    return (
        <LanguageContext.Provider value={value}>
            <Component
                key={`${languageClass}_${uniqueId.current}`}
                column
                center={center}
                {...rest}
                className={cx(className, languageClass, { [styles.full]: full })}
            >
                {emptyOnPending && isPending ? null : children}
            </Component>
        </LanguageContext.Provider>
    );
});

export function useLanguageContext() {
    return useContext(LanguageContext);
}

export function getString(key, area, replacers = {}, forceLanguage) {
    const lang = languageState.getLang(area);
    let val = strings[lang]?.[key] || key;
    Object.entries(replacers).forEach(([replacerKey, replacerValue]) => {
        val = val.replace(`{${replacerKey}}`, replacerValue);
    });
    return val;
}

export function getLang(area) {
    return languageState.getLang(area);
}

export function isRTL(area) {
    return area === LangArea.GAME ? languageState.isGameRTL : languageState.isAdminRTL;
}
