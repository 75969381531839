import React from 'react';
import styles from './UserIcon.module.scss';
import { isPending } from '../firebase/firebase-storage';
import Pulse from './Pulse';
import cx from 'classnames';
import Avatar from '@material-ui/core/Avatar/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { useUserData } from '../firebase/firebase-hooks';

const useStyles = makeStyles((theme) => ({
    icon: (props) => ({
        width: props.size || '34px',
        height: props.size || '34px',
        // marginInlineStart: props.marginInlineStart,
        color: theme.palette.getContrastText(purple[400]),
        backgroundColor: purple[400],
    }),
}));

export default function UserIcon({ marginInlineStart, size, userId, time, onClick, forcedIcon, options = {} }) {
    const classes = useStyles({ size, marginInlineStart });
    const outOfDate = time && Date.now() - time > 10000;

    const [user, , userStatus] = useUserData({ userId, ...options });
    const src = forcedIcon ? forcedIcon : user.photo;
    const style = marginInlineStart ? { marginInlineStart: '10px' } : {};
    return (
        <span
            style={style}
            onClick={onClick}
            className={cx(styles.main, { [styles.outOfDate]: outOfDate, [styles.isPending]: isPending(userStatus) })}
        >
            {!isPending(userStatus) ? <Avatar className={classes.icon} alt={user.displayName} src={src} /> : <Pulse />}
        </span>
    );
}
